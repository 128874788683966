const greenLight = {
	50: "#F2F9EC",
	100: "#DAEEC9",
	200: "#C3E3A6",
	300: "#ACD883",
	400: "#94CC60",
	500: "#7DC13E",
	600: "#649B31",
	700: "#4B7425",
	800: "#324D19",
	900: "#19270C",
};

const green = {
	50: "#F2F4F1",
	100: "#DBE0D7",
	200: "#C4CCBD",
	300: "#ADB8A3",
	400: "#96A489",
	500: "#80906F",
	600: "#667359",
	700: "#4D5643",
	800: "#33392D",
	900: "#1A1D16",
};

const orange = {
	50: "#F9F3EB",
	100: "#EFDFC7",
	200: "#E5CAA3",
	300: "#DBB580",
	400: "#D1A15C",
	500: "#C78C38",
	600: "#9F702D",
	700: "#775422",
	800: "#503816",
	900: "#281C0B",
};

const yellow = {
	50: "#FFF8E5",
	100: "#FFEBB8",
	200: "#FFDE8A",
	300: "#FFD25C",
	400: "#FFC52E",
	500: "#FFB800",
	600: "#CC9300",
	700: "#996E00",
	800: "#664A00",
	900: "#332500",
};

export const colors = {
	green,
	orange,
	yellow,
	primary: green,
	secondary: greenLight,
	terciary: orange,
};
