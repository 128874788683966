import React from "react";
import {
	Text,
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	Image,
} from "@chakra-ui/react";
import {
	AiOutlineMinus,
	AiOutlinePlus,
} from "react-icons/ai";

import spacing from "~/themes/spacing";

interface IProps {
	data: Array<{
		question: string;
		answer: string;
	}>;
}

export const FaqInfoCard: React.FC<IProps> = (props) => {
	const { data } = props;

	return (
		<Accordion
			allowMultiple
		>
			{
				data.map((item, index)=>(
					<AccordionItem
						key={index}
						bgColor="white"
						boxShadow="lg"
						border="solid 3px #f1f1f1"
						rounded="md"
						mt={spacing.s8}
					>
						{({ isExpanded }) => (
					  		<>
						  		<AccordionButton
									p={spacing.s4}
									_hover={{bgColor: "white"}}
								>
									<Box flex="1" textAlign="left">
							 			<Text
											variant="secondary"
											size="lg"
											fontWeight="bold"
										>
								 			{item.question}
							 			</Text>
									</Box>
									{isExpanded ? (
							  			<AiOutlineMinus />
									) : (
							  			<AiOutlinePlus />
									)}
						  		</AccordionButton>
								<AccordionPanel
									pb={spacing.s4}
								>
						  			<Text
										size="md"
										whiteSpace="pre-line"
									>
							  			{item.answer}
						  			</Text>
									{index === 5 && (
										<Image src="/MenuIndicator.png" mt={spacing.s2}/>
									)}
								</AccordionPanel>
					 		 </>
						)}
				  </AccordionItem>
				))
			}
		</Accordion>
	);
};
