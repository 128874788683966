import { FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { makeObservable, observable, action } from "mobx";
import { IRouterPusher } from "~/interfaces/IRouter";
import api from "~/resources/api";
import AuthStore from "~/stores/AuthStore";
import PaginatedListStore from "~/stores/PaginatedListStore";

interface IFormData{
	name: string;
	code: string;
}

export default class Store extends PaginatedListStore<api.Batch> {
	public authStore: AuthStore;
	public form: FormShelf<IFormData>;
	public selectedFilter: "code" | "name" | null = null;
	public loader = new LoaderShelf();

	constructor(router: IRouterPusher, authStore: AuthStore) {
		super(router);
		this.authStore = authStore;
		this.form = new FormShelf({
			name: "",
			code: "",
		});
		this.fetchPage(0);
		makeObservable(this, {
			selectedFilter: observable,
			onSearchByCode: action,
			onSearchByName: action,
		});
	}

	protected async getDataItemsPerPage(page: number): Promise<api.Batch[]> {
		return api.getAllBatchesCreatedByAdmin(
			page,
			{
				supplierName: this.form.field("name").value,
				supplierCode: this.form.field("code").value,
			});
	}
	public onSearchByName = () => {
		this.selectedFilter = "name";
		this.fetchPage(0);
		this.clearCode();
	};

	public onSearchByCode = () => {
		this.selectedFilter = "code";
		this.fetchPage(0);
		this.clearName();
	};

	private clearName = () => {
		this.form = new FormShelf({
			name: "",
			code: this.form.getValue("code"),
		});
	};

	private clearCode = () => {
		this.form = new FormShelf({
			name: this.form.getValue("name"),
			code: "",
		});
	};

	private clearForm = () => {
		this.form = new FormShelf({
			name: "",
			code: "",
		});
	};

}
