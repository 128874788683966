import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import {
	Flex,
	Heading,
	Td,
	Text,
	Tr,
	Button,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import {
	Table,
	TableCellWithActionButtons,
	Page,
	PageContent,
	TableFilter,
	PeriodFilterModal,
} from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";
import spacing from "~/themes/spacing";
import Store from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.user.table;
	const history = useHistory();
	const store = useLocalObservable(() => new Store(history));
	const { dialog } = useGlobalStore();

	const onDeleteUser = (id: string) => {
		store.deleteSupplier(id);
		dialog.closeDialog();
	};

	const openDialog = (admin: API.AdminUser) => {
		dialog.showDialog({
			title: pageStrings.modal.title,
			closeOnOverlayClick: true,
			description: pageStrings.modal.description(admin.name),
			buttons: [
				{
					title: pageStrings.modal.button.delete,
					onPress: () => onDeleteUser(admin.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: pageStrings.modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onGoToCreateAdminUser = () => history.push("suppliers/create");

	const onGoToEditAdminUser = (id: string) => history.push(`suppliers/edit/${id}`);

	const onGoToDetails = (id: string) => history.push(`suppliers/details/${id}`);

	return (
		<Page>
			<PageContent>
				<Heading
					variant="secondary"
					size="sm"
					color="primary.400"
					mb={spacing.s3}
				>
					{pageStrings.title}
				</Heading>
				<TableFilter
					name={{ ...store.form.field("name") }}
					code={{ ...store.form.field("code") }}
					onClickSearchName={store.onSearchByName}
					onClickSearchCode={store.onSearchByCode}
				/>
				<Flex w="100%" justifyContent="flex-end" p={spacing.s2}>
					{store.csvData && (
						<Button size="sm" onClick={() => store.periodFilterModal.setValue(true)}>
							<DownloadIcon />
						</Button>
					)}
				</Flex>
				<PeriodFilterModal
					title={pageStrings.downloadCSV.filter.title}
					isOpen={store.periodFilterModal.value}
					onClose={() => store.periodFilterModal.setValue(false)}
					onClick={() => {
						store.generateCSVProductsSuppliers();
						window.open(store.csvData);
					}}
					onSelectDateStart={store.periodFilterStart.setValue}
					onSelectDateEnd={store.periodFilterEnd.setValue}
					periodStart={store.periodFilterStart.value}
					periodEnd={store.periodFilterEnd.value}
				/>
				<Table
					minW="100%"
					data={store._items}
					headers={pageStrings.header}
					renderRow={(item) => (
						<Tr key={item.id} >
							<TableCellWithActionButtons
								onEdit={() => onGoToEditAdminUser(item.id)}
								onDelete={() => openDialog(item)}
							/>
							<Td>
								<Text variant="link" onClick={() => onGoToDetails(item.id)}>
									{item.name}
								</Text>
							</Td>
							<Td>
								<Text>{item.email}</Text>
							</Td>
							<Td>
								<Text>{item.code}</Text>
							</Td>
						</Tr>
					)}
					loading={store._loading}
					emptyMessage={strings.common.noResults}
					currentPage={store._page}
					prevPage={store.previousPage}
					nextPage={store.nextPage}
					isHaveNextPage={store._isHaveNextPage}
					onAdd={onGoToCreateAdminUser}
				/>
			</PageContent>
		</Page>
	);
};

export default observer(TableView);
