import React from "react";
import {
	Tr,
	Td,
	Text,
	Switch,
	FormControl,
	Button,
	Flex,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { MoneyInput, TableImageWithLabel } from "..";
import {
	format,
	strings,
	api,
} from "~/resources";
import { DetailsProduct } from "~/shelves/DetailsBatchShelf";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import ImagePickerShelf from "~/shelves/ImagePicker";
interface IProps {
	data: DetailsProduct;
	batchId: string | undefined;
	dataIndex: number;
	isDisabled: boolean;
	editProduct: (dataId: string, batchId: string, editProduct: api.EditProduct) => void;
	onChangeMoney: (index: number, value: string | null) => void;
	onChangeIsAccepted: (index: number, isAccepted: boolean) => void;
	setReviewedProduct: (id: string) => void;
	hasAnswered?: boolean;
	isPendingAdmin?: boolean;
	isReviewer: boolean;
	isPending: boolean;
	firstImage: ImagePickerShelf;
	secondImage: ImagePickerShelf;
	thirdImage: ImagePickerShelf;
}

const pageStrings = strings.components.tableReviewBatch;

export const TableReviewBatchAdmin: React.FC<IProps> = (props: IProps) => {
	const {
		data,
		dataIndex,
		isDisabled,
		setReviewedProduct,
		onChangeMoney,
		onChangeIsAccepted,
		hasAnswered,
		isPendingAdmin,
		isReviewer,
		batchId,
		editProduct,
		isPending,
		firstImage,
		secondImage,
		thirdImage,
	} = props;

	const accepted = !!data.acceptedAt || data.isAccepted;
	const { dialog } = useGlobalStore();
	const [checked, setChecked] = React.useState(accepted);
	const [price, setPrice] = React.useState("");
	const imageUrls = data.images.map((image) => image.url);

	const setProductData = () => {
		if(data.images.length > 0){
			firstImage.getPickerFields().setUploadedImage(data.images[0]);
		}
		if(data.images.length > 1){
			secondImage.getPickerFields().setUploadedImage(data.images[1]);
		}
		if(data.images.length > 2){
			thirdImage.getPickerFields().setUploadedImage(data.images[2]);
		}
	};

	const uploadedImages = () => {
		setProductData();
		const images: api.UncertainImage[] = [];
		const imagePickers = [
			firstImage,
			secondImage,
			thirdImage,
		];
		imagePickers.forEach((imagePicker) => {
			if (imagePicker.uncertainfiedImage) {
				images.push(imagePicker.uncertainfiedImage);
			}
		});
		return images;
	};

	const editForm: api.EditProduct = {
		images: uploadedImages(),
		code: data.code,
		title: data.title,
		brand: data.brand,
		size: data.size,
		suggestedValue: Number(price),
	};

	const openDialog = () => {
		dialog.showDialog({
			title: pageStrings.edit,
			closeOnOverlayClick: true,
			description: pageStrings.descriptionModal,
			buttons: [
				{
					title: strings.actions.send,
					onPress: () => {
						dialog.closeDialog();
						editProduct(data.id, batchId || "", editForm);
					},
				},
				{
					title: strings.actions.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Tr key={data.id} >
			<TableImageWithLabel imageUrls={imageUrls} code={data.code} />
			<Td>
				<Text>{data.brand}</Text>
			</Td>
			<Td>
				<Text>{data.title || "-"}</Text>
			</Td>
			<Td>
				<Text>{data.size ? api.translateSize(data.size) : "-"}</Text>
			</Td>
			<Td>
				<Text>{format.currencyForBR(data.suggestedValue)}</Text>
			</Td>
			{hasAnswered && (
				<Td>
					<Text>{data.acceptedAt ? pageStrings.accepted : pageStrings.refused}</Text>
				</Td>
			)}
			{hasAnswered ? (
				<>
					<Td>
						<Text color="gray.500">{format.currencyForBR(data.lastProposedPrice || 0)}</Text>
					</Td>
					<Td>
						<FormControl>
							<Switch
								defaultChecked={accepted}
								isDisabled={isDisabled || !!data.acceptedAt}
								onChange={() => {
									setReviewedProduct(data.id);
									setChecked(!checked);
									onChangeIsAccepted(dataIndex, !checked);
									if (!checked && !isPendingAdmin) {
										onChangeMoney(dataIndex, null);
										setPrice("0");
									}
								}}
							/>
						</FormControl>
					</Td>
				</>
			) : (
				<>
					<Td>
						<FormControl>
							<Switch
								defaultChecked={accepted}
								isDisabled={isDisabled || !!data.acceptedAt}
								onChange={() => {
									setReviewedProduct(data.id);
									setChecked(!checked);
									onChangeIsAccepted(dataIndex, !checked);
									if (!checked) {
										onChangeMoney(dataIndex, null);
										setPrice("0");
									}
								}}
							/>
						</FormControl>
					</Td>
					<Td>
						<Flex gap={4}>
							<MoneyInput
								value={price}
								defaultValue={data.newSuggestedValue ? format.decimal(data.newSuggestedValue) : null}
								isDisabled={!isPending}
								onChange={(value: string) => {
									onChangeMoney(dataIndex, value);
									setPrice(value);
								}}
							/>
							{isPending && (
								<Button
									ml={2}
									w={10}
									onClick={() => openDialog()}
								>
									<EditIcon />
								</Button>
							)}
						</Flex>
					</Td>
				</>
			)}
			{isPendingAdmin && isReviewer && (
				<Td>
					<MoneyInput
						value={price}
						defaultValue={data.newSuggestedValue ? format.decimal(data.newSuggestedValue) : null}
						isDisabled={checked}
						onChange={(value: string) => {
							onChangeMoney(dataIndex, value);
							setPrice(value);
						}}
					/>
				</Td>
			)}
		</Tr>
	);
};

