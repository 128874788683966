import { ComponentStyleConfig } from "@chakra-ui/react";
import { accordionAnatomy } from "@chakra-ui/anatomy";

const accordionParts = accordionAnatomy.classNames;
const { button } = accordionParts;

export const Accordion: ComponentStyleConfig = {
	parts: [button],
	baseStyle: {
		button: {
			color: "primary.500",
			fontSize: "lg",
		},
	},
};
