import React from "react";
import { Flex } from "@chakra-ui/react";
import spacing from "../../themes/spacing";
import { SearchInput } from "..";
import { strings } from "~/resources";

interface Field {
	name: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IProps {
	name: Field;
	code: Field;
	onClickSearchName: () => void;
	onClickSearchCode: () => void;
}

const pageStrings = strings.components.tableFilter;

export const TableFilter: React.FC<IProps> = (props) => {
	const {
		name,
		code,
		onClickSearchName,
		onClickSearchCode,
	} = props;

	return (
		<Flex
			flexDir={["column", "column", "row"]}
			w="100%"
			mb={spacing.s4}
		>
			<SearchInput
				{...name}
				placeholder={pageStrings.searchByNamePlaceholder}
				onClickSearch={onClickSearchName}
				containerProps={{
					w: ["100%", "100%", "48%"],
					mr: [0, 0, "auto"],
					mb: [spacing.s4, spacing.s4, 0],
				}}
			/>
			<SearchInput
				{...code}
				placeholder={pageStrings.searchByCodePlaceholder}
				onClickSearch={onClickSearchCode}
				containerProps={{
					w: ["100%", "100%", "48%"],
				}}
			/>
		</Flex>
	);
};
