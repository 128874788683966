import { makeAutoObservable } from "mobx";
import {
	LoaderShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import AuthStore from "~/stores/AuthStore";
import strings from "~/resources/strings";

export default class Store {
	public loader = new LoaderShelf();
	public notifications: api.Notification[] = [];

	private authStore: AuthStore;

	constructor(authStore: AuthStore) {
		this.authStore = authStore;
		makeAutoObservable(this);
	}

	private setNotifications = (notifications: api.Notification[]) => {
		this.notifications = notifications;
	};

	private checkIsLogged = () => {
		if (!this.authStore.currentAdminUser) {
			throw strings.error.notLoggedIn;
		}
		return this.authStore.currentAdminUser;
	};

	public async getNotifications() {
		try {
			this.loader.tryStart(strings.error.stillLoading);
			const user = this.checkIsLogged();

			let  notifications: api.Notification[];
			if (!user.code) {
				notifications = await api.getAllNotificationsForAdminUser();
				this.setNotifications(notifications);
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	}

	public async setNotificationRead(notificationId: string) {
		try {
			this.loader.tryStart(strings.error.stillLoading);
			await api.readNotification(notificationId);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	}
}
