import React from "react";
import { observer } from "mobx-react-lite";
import { Heading } from "@chakra-ui/react";

import {
	Page,
	PageContent,
	FaqInfoCard,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";


const Faq: React.FC = () => {

	const pageStrings = strings.faq;

	return (
		<Page>
			<PageContent>
				<Heading
					mb={spacing.s4}
					size="lg"
				>
					{pageStrings.title}
				</Heading>
				<FaqInfoCard
					data={pageStrings.questions}
				/>
			</PageContent>
		</Page>
	);
};

export default observer(Faq);
