const format = {
	currency: (centsValue: number) => {
		return (centsValue / 100).toLocaleString(
			window !== undefined ? window.navigator.language : "pt-BR",
			{
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			},
		);
	},
	formatCurrencyBR: (currentNumber: number) => {
		return new Intl.NumberFormat("pt-br", {
			style: "currency",
			currency: "BRL",
		}).format(currentNumber);
	},
	currencyForBR: (centsValue: number) => {
		return (centsValue / 100).toLocaleString("pt-BR", {
			currency: "BRL",
			style: "currency",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		});
	},
	decimal: (value: number) => {
		return ((value / 100).toLocaleString(
			"pt-BR",
			{
				style: "decimal",
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			})
		);
	},
	cleanDecimal: (unCleanAmount: string) => {
		return unCleanAmount.replace(/\D/g, "");
	},
};

export default format;
