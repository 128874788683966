import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
	defaultProps: {
		variant: "filled",
		size: "md",
	},
	sizes: {
		xs: {
			w: "3rem",
			fontSize: "18px",
		},
		md: {
			w: "12rem",
			fontSize: "16px",
		},
	},
	variants: {
		filled: {
			_loading: {
				_hover: {
					bg:"primary.500",
				},
			},
			bg: "primary.500",
			color: "white",
			borderRadius:"10px",
			size: "md",
		},
		link: {
			textDecoration: "underline",
			color: "primary.500",
			fontSize: "sm",
		},
		iconGreen: {
			color: "primary.500",
		},
		iconGray: {
			color: "gray.400",
		},
	},
};
