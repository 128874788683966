import React from "react";
import { Box, Flex, Image, Center } from "@chakra-ui/react";
import orangeLogoSvg from "../../../static/logo_orange.svg";
import spacing from "~/themes/spacing";
import { NavbarLinks } from "./NavbarLinks";
import { Icon } from "@chakra-ui/icons";
import { IoNotificationsSharp } from "react-icons/io5";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";

export interface INavbarProps {
	countOfNewNotifications?: number | null;
	onClickRing?: (onSuccess?: () => void) => void;
}

export const Navbar: React.FC<INavbarProps> = (props) => {
	const { countOfNewNotifications, onClickRing } = props;
	const history = useHistory();
	const { authStore } = useGlobalStore();
	const onGotoHome = () => {
		if (onClickRing) {
			onClickRing(() => history.push("/dashboard"));
		}
	};

	return (
		<Flex
			position="sticky"
			top={0}
			zIndex={1000}
			h="76px"
			w="100%"
			flexDirection="column"
			bg="green.800"
		>
			<Flex
				h="100%"
				w="100%"
				justifyContent="space-between"
				alignItems="center"
				px={{base: spacing.s5 , lg: spacing.s12}}
			>
				<NavbarLinks />

				<Flex alignItems="center">
					<Image
						h="40px"
						mr={5}
						src={orangeLogoSvg}
					/>
					{!authStore.currentAdminUser?.code && (
						<Box
							position="relative"
							cursor="pointer"
							onClick={onGotoHome}
						>
							<Icon
								as={IoNotificationsSharp}
								w={{ base: 6, sm: 7 }}
								h={{ base: 6, sm: 7 }}
								mr={spacing.s4}
								color="white"
							/>
							{countOfNewNotifications
								? (
									<Center
										position="absolute"
										top={-1}
										right={3}
										bg="red.500"
										color="white"
										rounded="full"
										fontSize="xs"
										w="18px"
										h="18px"
									>
										{strings.components.nav.countOfNotifications(countOfNewNotifications)}
									</Center>
								)
								: null
							}
						</Box>
					)}

				</Flex>

			</Flex>
			<Box
				w="100%"
				h="5px"
				bgGradient="linear(to-r, primary.300, primary.800)"
			/>
		</Flex>
	);
};
