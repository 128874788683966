import React from "react";
import RDatePicker from "react-datepicker";
import { Box, Input, InputProps } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";

interface IProps {
	onChange: (date: Date) => void;
	selectedDate: Date;
	inputProps?: InputProps;
}

export const DatePicker: React.FC<IProps> = (props) => {
	const {onChange, selectedDate, inputProps} = props;
	return (
		<Box>
			<RDatePicker
				locale={ptBR}
				dateFormat={"dd/MM/yyyy"}
				selected={selectedDate}
				onChange={onChange}
				customInput={<Input {...inputProps} />}
			/>
		</Box>
	);

};
