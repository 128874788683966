import { action, makeObservable, observable, runInAction } from "mobx";
import { AttributeShelf, FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { IRouterPusher } from "~/interfaces/IRouter";
import PaginatedListStore from "~/stores/PaginatedListStore";

interface IFormData {
	name: string;
	code: string;
}

const pageStrings = strings.user.table;

export default class Store extends PaginatedListStore<api.AdminUser> {
	public form: FormShelf<IFormData>;
	public selectedFilter: "code" | "name" | null = null;
	public loader = new LoaderShelf();
	public loaderCSV = new LoaderShelf();
	public csvData = "";
	public suppliers: api.AdminUser[] = [];
	public periodFilterModal = new AttributeShelf(false);
	public periodFilterStart = new AttributeShelf<Date | null>(null);
	public periodFilterEnd = new AttributeShelf<Date | null>(null);

	constructor(router: IRouterPusher) {
		super(router);
		this.form = new FormShelf({
			name: "",
			code: "",
		});
		makeObservable(this, {
			suppliers: observable,
			selectedFilter: observable,
			csvData: observable,
			deleteSupplier: action,
			onSearchByCode: action,
			onSearchByName: action,
			getSuppliers: action,
		});
		this.fetchPage(0);
		this.generateCSVProductsSuppliers();
	}

	protected getDataItemsPerPage(page: number): Promise<api.AdminUser[]> {
		const nameValue = this.form.field("name").value;
		const codeValue = this.form.field("code").value;

		if (!nameValue && !codeValue) {
			this.selectedFilter = null;
		}

		if (this.selectedFilter === "name" || this.selectedFilter === "code") {
			return this.getSuppliers(page);
		}
		return api.getAllSuppliers(page);
	}

	public onSearchByName = () => {
		this.selectedFilter = "name";
		this.fetchPage(0);
		this.clearCode();
	};

	public onSearchByCode = () => {
		this.selectedFilter = "code";
		this.fetchPage(0);
		this.clearName();
	};

	private clearName = () => {
		this.form = new FormShelf({
			name: "",
			code: this.form.getValue("code"),
		});
	};

	private clearCode = () => {
		this.form = new FormShelf({
			name: this.form.getValue("name"),
			code: "",
		});
	};

	private clearForm = () => {
		this.form = new FormShelf({
			name: "",
			code: "",
		});
	};

	public getSuppliers = async (page: number) => {
		this.loader.tryStart();
		try {
			const users = this.selectedFilter === "code" ?
				await api.getSuppliersByCode(this.form.field("code").value, page)
				:
				await api.getSuppliersByName(this.form.field("name").value, page);
			this.suppliers = users;

			if (users.length < 1) {
				showErrorToast(strings.common.noResults);
				this.clearForm();
			}
			this.suppliers = users;
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
		return this.suppliers;
	};

	public generateCSVProductsSuppliers = async () => {
		this.loaderCSV.tryStart();
		try {
			const data = await api.exportCSVProductsSuppliers(
				{
					startDate: this.periodFilterStart.value,
					endDate: this.periodFilterEnd.value,
				},
			);
			runInAction(() => this.csvData = data);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loaderCSV.end();
		}
	};

	public deleteSupplier = async (id: string) => {
		this.loader.tryStart();
		try {
			const deletedUser = await api.deleteAdminUser(id);
			showSuccessToast(pageStrings.delete(deletedUser.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
