import React from "react";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { api, showErrorToast, strings } from "~/resources";

const useCountNotifications = () => {
	const { authStore } = useGlobalStore();

	const [notificationsCount, setNotificationsCount] = React.useState<number | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);

	const getNewNotifications = async () => {
		if (loading) {
			return;
		}
		setLoading(true);
		try {
			const count = await api.getCountOfNotViewedNotificationsForAdmin();
			setNotificationsCount(count);
		} catch (e) {
		}finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		const isAdminUser = authStore.currentAdminUser && !authStore.currentAdminUser.code;
		if (isAdminUser) {
			getNewNotifications();
		}
		setNotificationsCount(null);
	}, [authStore.currentAdminUser]);

	const onReadAllNotifications = async (onSuccess?: () => void) => {
		if (loading) {
			return;
		}
		setLoading(true);

		try {
			await api.viewAllNotifications();

			setNotificationsCount(0);
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			showErrorToast(strings.error.default);
		}finally {
			setLoading(false);
		}
	};

	return {
		notificationsCount,
		onReadAllNotifications,
	};
};

export default useCountNotifications;
