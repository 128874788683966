import { ComponentStyleConfig } from "@chakra-ui/react";

export const Switch: ComponentStyleConfig = {
	baseStyle: {
		track: {
			bg:"red.400",
			_checked: {
				bg: "secondary.400",
			},
		},
	},
};
