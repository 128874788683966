import React from "react";
import { observer } from "mobx-react-lite";
import { Center } from "@chakra-ui/react";
import {
	Page,
	PageContent,
	Loading,
} from "~/components";
import spacing from "~/themes/spacing";

export const PageLoading: React.FC = observer(() => {
	return (
		<Page>
			<PageContent>
				<Center mt={spacing.s8} >
					<Loading/>
				</Center>
			</PageContent>
		</Page>
	);
});
