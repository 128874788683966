import React from "react";
import {
	Input,
	InputGroup,
	InputRightElement,
	Box,
	BoxProps,
	IconButton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import spacing from "../../themes/spacing";

const { s2 } = spacing;

interface IProps {
	name: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClickSearch: () => void;
	placeholder?: string;
	containerProps?: BoxProps;
	isReadOnly?: boolean;
	isFilled?: boolean;
}

export const SearchInput: React.FC<IProps> = (props) => {
	const {
		name,
		value,
		onChange,
		onClickSearch,
		placeholder,
		containerProps,
		isReadOnly,
		isFilled,
	} = props;

	const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === "Enter") {
			onClickSearch();
		}
	};

	return (
		<Box h={58} {...containerProps}>
			<InputGroup h="100%" w="100%">
				<Input
					variant={isFilled ? "filled" : "alwaysWhite"}
					name={name}
					value={value}
					h="100%"
					placeholder={placeholder}
					focusBorderColor="primary.700"
					isReadOnly={!!isReadOnly}
					onKeyPress={onPressEnter}
					onChange={onChange}
				/>
				<InputRightElement h="100%">
					<IconButton
						h="100%"
						aria-label={placeholder || ""}
						icon={<SearchIcon />}
						onClick={onClickSearch}
						color="primary.700"
						bgColor="transparent"
						mr={s2}
					/>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
};
