import React from "react";
import {
	Modal,
	Heading,
	Flex,
	ModalOverlay,
	ModalContent,
	Button,
	ModalBody,
	ModalFooter,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FormShelf } from "@startapp/mobx-utils";

import { TextInput, MoneyInput, Label, MultipleImagePickers, SearchInput, EnumPicker } from "..";
import { Image } from "../MultipleImagePickers";
import spacing from "~/themes/spacing";
import strings from "~/resources/strings";
import { BrandList, BrandListProps } from "./BrandList";
import { api } from "../../resources";

export interface IProductFormData {
	code: string;
	title: string;
}

interface IProps {
	disabledInputs: boolean;
	loading: boolean;
	images: Image[];
	isOpen: boolean;
	closeOnOverlayClick: boolean;
	suggestedPrice: string;
	onChangeSuggestedPrice: (value: string) => void;
	onAddProduct: () => void;
	onClose: () => void;
	formShelf: FormShelf<IProductFormData>;
	shouldShowCodeField: boolean;
	listProps: BrandListProps;
	searchValue: string;
	onChangeSearchValue: (value: string) => void;
	onClickSearch: () => void;
	selectedBrand: string;
	selectedSize: api.Size | null;
	onSelectSize: (selected: api.Size) => void;
}

export const ProductFormModal: React.FC<IProps> = observer((props) => {

	const [showList, setShowList] = React.useState(false);
	const hideList = () => setShowList(false);
	const onShowList = () => setShowList(true);

	const {
		disabledInputs, formShelf,
		suggestedPrice, onChangeSuggestedPrice,
		onAddProduct, loading,
		onClose, isOpen,
		closeOnOverlayClick, images, shouldShowCodeField,
		listProps, searchValue, onChangeSearchValue,
		onClickSearch, selectedBrand,
		onSelectSize, selectedSize,
	} = props;

	const componentStrings = strings.components.productFormModal;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalBody
					alignItems="center"
					justifyContent="center"
					color="primary.500"
					justifyItems="center"
					p={"20px"}
					w={"100%"}
				>
					<Heading marginBottom={spacing.s3}>
						{componentStrings.title}
					</Heading>
					{showList ? (
						<>
							<SearchInput
								isFilled
								name="supplierSearchModal"
								value={searchValue}
								onChange={(e) => {
									onChangeSearchValue(e.currentTarget.value);
								}}
								onClickSearch={onClickSearch}
								placeholder={componentStrings.placeholder.searchName}
								containerProps={{ w: ["100%"], my: spacing.s8 }}
							/>
							<BrandList
								searchValue={searchValue}
								onChangeSearchValue={onChangeSearchValue}
								{...listProps}
							/>
						</>
					):(
						<>
							<MultipleImagePickers images={images} />
							{shouldShowCodeField && (
								<TextInput
									marginBottom={spacing.s3}
									labelText={componentStrings.fields.code}
									type="text"
									isDisabled={disabledInputs}
									{...formShelf.field("code")}
								/>
							)}
							<TextInput
								marginBottom={spacing.s3}
								labelText={componentStrings.fields.title}
								helper={componentStrings.fields.optional}
								type="text"
								isDisabled={disabledInputs}
								{...formShelf.field("title")}
							/>
							<TextInput
								marginBottom={spacing.s3}
								labelText={componentStrings.fields.brand}
								type="text"
								isDisabled={disabledInputs}
								onClick={onShowList}
								onChange={()=>{}}
								value={selectedBrand}
							/>
							<EnumPicker
								items={api.allValuesSize()}
								tranlateEnum={api.translateSize}
								onChangeEnum={onSelectSize}
								currentValue={selectedSize}
								label={componentStrings.fields.size}
							/>
							<Label marginTop={spacing.s2} marginBottom={spacing.s1}>
								{componentStrings.fields.suggestedPrice}
							</Label>
							<MoneyInput
								value={suggestedPrice}
								onChange={onChangeSuggestedPrice}
							/>
						</>
					)}
				</ModalBody>
				<ModalFooter p={spacing.s1}>
					<Flex
						marginTop={5}
						flexDir="column"
						justifyContent="center"
					>
						{showList ? (
							<>
								<Button
									mt={spacing.s2}
									mb={spacing.s6}
									onClick={hideList}
								>
									{strings.actions.select}
								</Button>
								<Button
									mt={spacing.s2}
									mb={spacing.s6}
									variant="link"
									onClick={hideList}
								>
									{strings.actions.back}
								</Button>
							</>
						):(
							<>
								<Button
									disabled={loading}
									onClick={onAddProduct}
								>
									{strings.actions.save}
								</Button>
								<Button
									mt={spacing.s2}
									mb={spacing.s6}
									variant="link"
									onClick={onClose}
								>
									{strings.actions.cancel}
								</Button>
							</>
						)}
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
});
