import React from "react";
import {
	Tr,
	Td,
	Text,
} from "@chakra-ui/react";
import {
	TableImageWithLabel,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import moment from "moment";
import format from "~/resources/format";

interface IProps{
	data: api.Product;
}

export const TableRowProduct: React.FC<IProps> = (props: IProps) => {
	const { data } = props;

	const imageUrls = data.images.map((image) => image.url);
	const price = format.currencyForBR(data.lastProposedPrice);
	return (
		<Tr key={data.id}>
			<TableImageWithLabel imageUrls={imageUrls} code={data.code} />
			<Td>
				<Text>{data.title || "-"}</Text>
			</Td>
			<Td>
				<Text>{data.brand}</Text>
			</Td>
			<Td>
				<Text>{data.size ? api.translateSize(data.size) : "-"}</Text>
			</Td>
			<Td>
				<Text>{price}</Text>
			</Td>
			<Td>
				<Text>{data.acceptedAt ? moment(data.acceptedAt).format(strings.moment.date) : "-"}</Text>
			</Td>
		</Tr>
	);
};
