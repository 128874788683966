import { ComponentStyleConfig } from "@chakra-ui/react";
import spacing from "../../spacing";

const { s5, s6, s8, s10 } = spacing;

export const Card: ComponentStyleConfig = {
	defaultProps: {
		variant: "info",
		size: "md",
	},
	baseStyle: {
		display: "flex",
		boxShadow: "md",
		justifyContent: "center",
		alignItems: "center",
		bg: "white",
		rounded: "lg",
		maxW: ["90vw", "100%"],
	},
	variants: {
		form: {
			flexDir: "column",
		},
		info: {
			flexDir: ["column", "row"],
		},
	},
	sizes: {
		sm: {
			py: s6,
			px: s5,
		},
		md: {
			py: s6,
			px: s8,
		},
		lg: {
			py: s8,
			px: s10,
		},
	},
};
