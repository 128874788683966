import React from "react";
import { Flex, FlexProps, useBreakpointValue } from "@chakra-ui/react";
import spacing from "~/themes/spacing";
import useWindowDimensions from "~/hooks/useWindowDimensions";
import { TextInfoComponent } from "../TextInfoComponent";

interface IProps extends FlexProps {
	hideInfoTextMobile?: boolean;
}

export const PageContent: React.FC<IProps> = (props) => {
	const {
		hideInfoTextMobile,
		children,
		...rest
	} = props;

	const { width, height } = useWindowDimensions();
	const size = useBreakpointValue({ base: width > height ? "none" : "flex",  md: "none" });

	return (
		<Flex
			w="100%"
			maxW="1200px"
			mx={{ base: spacing.s10, xl: "auto" }}
			flexDirection="column"
			{...rest}
		>
			{!hideInfoTextMobile && (
				<Flex marginBottom={spacing.s10} display={size}>
					<TextInfoComponent />
				</Flex>

			)}
			{children}
		</Flex>
	);
};
