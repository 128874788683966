import { makeAutoObservable, runInAction } from "mobx";
import {
	LoaderShelf,
} from "@startapp/mobx-utils";
import { IRouterPusher } from "~/interfaces/IRouter";
import CreateBatchShelf from "~/shelves/CreateBatchShelf";
import SuppliersShelf from "~/shelves/SuppliersShelf";
import BrandsShelf from "~/shelves/BrandsShelf";
import { api, Errors, showErrorToast } from "~/resources";
import AuthStore from "~/stores/AuthStore";

export default class Store {

	public loader = new LoaderShelf();
	public isFetched = false;

	public suppliersShelf: SuppliersShelf;
	public createBatchShelf: CreateBatchShelf;
	public brandsShelf: BrandsShelf;

	constructor(router: IRouterPusher, authStore: AuthStore, id?: string) {
		this.createBatchShelf = new CreateBatchShelf(router, authStore);
		this.suppliersShelf = new SuppliersShelf(router);
		this.brandsShelf = new BrandsShelf(router);

		if(id){
			this.getBatch(id);
		}
		makeAutoObservable(this);
	}

	public getBatch = async (id: string) => {
		try {
			const resultBatch = await api.getBatchById(id);

			this.createBatchShelf.setBatch(resultBatch);
			this.createBatchShelf.productsShelf.setBatch(resultBatch);

			if(resultBatch.status !== api.BatchStatus.creating){
				return;
			}

			this.createBatchShelf.productsShelf.refresh();

			if(resultBatch.userReceiver){
				this.suppliersShelf.onSelectItem(resultBatch.userReceiver);
				this.suppliersShelf.search.onChangeValue(resultBatch.userReceiver.name);
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			runInAction(() => this.isFetched = true);
			this.loader.end();
		}
	};
}
