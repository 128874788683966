import { action, makeObservable, observable, runInAction } from "mobx";
import {
	LoaderShelf,
} from "@startapp/mobx-utils";

import api from "~/resources/api";

import {
	Errors,
	showErrorToast,
	showSuccessToast,
	strings,
} from "~/resources";
import ProductsShelf from "../ProductsShelf";
import { IRouterPusher } from "~/interfaces/IRouter";
import AuthStore from "~/stores/AuthStore";

const pageStrings = strings.commonBatches.create;

export default class CreateBatchShelf {
	private authStore: AuthStore;
	public batch: api.Batch | null = null;
	public loader = new LoaderShelf();
	public acceptTermLoader = new LoaderShelf();
	public productsShelf: ProductsShelf;

	constructor(router: IRouterPusher, authStore: AuthStore) {
		this.authStore = authStore;
		this.productsShelf = new ProductsShelf(router, this.getProductsFromBatch);
		makeObservable(this, {
			batch: observable,
			setBatch: action,
		});
	}

	private getProductsFromBatch = async (page: number): Promise<api.ProductsWithPaginationInfo> => {
		if (!this.batch) {
			return {
				items: [],
				totalPages: 1,
			};
		}
		return api.getProductsByBatch(this.batch.id, page);
	};

	public setBatch = (batch: api.Batch) => this.batch = batch;

	public confirmBatchCreation = async (onSuccess?: () => void) => {
		this.loader.tryStart();
		try {
			if (!this.batch) {
				throw pageStrings.error.needSelected;
			}

			await api.confirmBatchCreation(this.batch.id);
			showSuccessToast(pageStrings.success.batchConfirmed);
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public createBatch = async (userId: string | null = null, onSuccess?: () => void) => {
		this.loader.tryStart();
		try {
			const batch = await api.createBatch(userId);
			runInAction(() => {
				this.batch = batch;
			});
			this.productsShelf.setBatch(batch);
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public acceptTermOfUseAndConfirmBatch = async(onSuccess?: () => void) => {
		this.acceptTermLoader.tryStart();
		try {
			await api.acceptTermsOfUse();
			await this.confirmBatchCreation();
			await this.authStore.getCurrentAdminUser();
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.acceptTermLoader.end();
		}
	};
}
