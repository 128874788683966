import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import {
	DatePickerModal,
	Loading,
	Page,
	PageContent,
	Table,
	TableRowProduct,
	UserInfoCard,
} from "~/components";
import {
	AiOutlineFilter,
} from "react-icons/ai";
import spacing from "~/themes/spacing";
import Store from "./store";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
const Profile: React.FC = () => {

	const { authStore } = useGlobalStore();

	const history = useHistory();
	const store = useLocalObservable(() => new Store(history, authStore));

	const pageStrings = strings.profile;
	const currentUser = authStore.currentAdminUser;

	const [isOpen, setIsOpen] = React.useState(false);

	const onClose = () => setIsOpen(false);

	React.useEffect(() => {
		if(authStore.currentAdminUser){
			store.fetchPage(0);
		}
	}, [authStore.currentAdminUser]);

	return (
		<Page>
			<PageContent>
				{!currentUser ? (
					<Loading mt={spacing.s10} />
				) : (
					<>
						<Box mb={spacing.s10}>
							<Heading
								variant="secondary"
								size="sm"
								color="primary.400"
								mb={spacing.s3}
							>
								{pageStrings.title}
							</Heading>
							<UserInfoCard
								supplier={currentUser.name}
								code={currentUser.code || ""}
								email={currentUser.email}
							/>
						</Box>
						<Flex wrap="wrap">
							<Heading
								flexGrow={1}
								variant="secondary"
								size="sm"
								color="primary.400"
								mb={spacing.s2}
							>
								{pageStrings.parts}
							</Heading>
							<Button
								size="sm"
								onClick={() => setIsOpen(true)}
								rightIcon={<AiOutlineFilter />}
								mb={spacing.s2}
							>
								{pageStrings.filter}
							</Button>
							<DatePickerModal
								title={pageStrings.filter}
								isOpen={isOpen}
								onClose={onClose}
								onClick={store.onFilterDate}
								onChange={store.setFilterValue}
								selectedDate={store.filterValue || new Date()}
							/>
						</Flex>

						<Table
							minW="100%"
							data={store._items}
							headers={pageStrings.header}
							renderRow={(item) => (<TableRowProduct key={item.id} data={item}/>)}
							loading={store._loading}
							emptyMessage={strings.common.noResults}
							currentPage={store._page}
							isHaveNextPage={store._isHaveNextPage}
							prevPage={store.previousPage}
							nextPage={store.nextPage}
						/>
					</>
				)}
			</PageContent>
		</Page>
	);
};

export default observer(Profile);
