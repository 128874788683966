import React from "react";
import { observer } from "mobx-react-lite";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	Flex,
} from "@chakra-ui/react";
import { SearchInput } from "../../";
import { SearchList, SearchListProps} from "../SearchList";
import spacing from "~/themes/spacing";
import strings from "~/resources/strings";

interface IProps{
	isOpen: boolean;
	closeOnOverlayClick?: boolean;
	onClose: () => void;
	onBack: () => void;
	onAdd: () => void;
	searchValue: string;
	onChangeSearchValue: (value: string) => void;
	onClickSearch: () => void;
	tableProps: SearchListProps;
}

export const SearchModal: React.FC<IProps> = observer((props) => {
	const {
		onClose,
		isOpen,
		closeOnOverlayClick = true,
		searchValue,
		onClickSearch,
		onChangeSearchValue,
		onBack,
		onAdd,
		tableProps,
	} = props;

	const componentStrings = strings.components.searchSupplierModal;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				w={{base: "80%", md: "full"}}
				maxH="95%"
				overflowY="auto"
			>
				<ModalBody color="primary.500" px={spacing.s2}>
					<SearchInput
						isFilled
						name="supplierSearchModal"
						value={searchValue}
						onChange={(e) => {
							onChangeSearchValue(e.currentTarget.value);
						}}
						onClickSearch={onClickSearch}
						placeholder={componentStrings.placeholder.searchName}
						containerProps={{ w: ["100%"], my: spacing.s8 }}
					/>
					<SearchList
						searchValue={searchValue}
						onChangeSearchValue={onChangeSearchValue}
						{...tableProps}
					/>
				</ModalBody>

				<ModalFooter>
					<Flex flexDir="column" justifyContent="center">
						<Button
							onClick={()=>{
								onAdd();
								onClose();
							}}
						>
							{componentStrings.select}
						</Button>
						<Button
							mt={spacing.s8}
							variant="link"
							onClick={()=>{
								onBack();
								onClose();
							}}
						>
							{strings.actions.back}
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

});

