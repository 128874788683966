import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import {
	Heading,
	Td,
	Text,
	Tr,
	useToast,
} from "@chakra-ui/react";
import {
	Page,
	PageContent,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import { api, strings } from "~/resources";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";
import spacing from "~/themes/spacing";

const TableView: React.FC = () => {
	const pageStrings = strings.adminUser.table;
	const history = useHistory();
	const store = useLocalObservable(() => new Store(history));
	const { dialog } = useGlobalStore();
	const toast = useToast();

	const onDeleteUser = (id: string) => {
		const onSuccess = (userName: string) => toast({status:"success", title: pageStrings.delete(userName) });
		const onError = (errorMessage: string) => {
			toast({status: "error", title: errorMessage});
		};
		store.deleteAdminUser(id, {onSucess: onSuccess, onError });
		dialog.closeDialog();
	};

	const openDialog = (admin: api.AdminUser) => {
		dialog.showDialog({
			title: pageStrings.modal.title,
			closeOnOverlayClick: true,
			description: pageStrings.modal.description(admin.name),
			buttons: [
				{
					title: pageStrings.modal.button.delete,
					onPress: () => onDeleteUser(admin.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: pageStrings.modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onGoToCreateAdminUser = () => history.push("adminUsers/create");

	const onGoToEditAdminUser = (id: string) => history.push(`adminUsers/edit/${id}`);

	return (
		<Page>
			<PageContent>
				<Heading
					variant="secondary"
					size="sm"
					color="primary.400"
					mb={spacing.s3}
				>
					{pageStrings.title}
				</Heading>
				<Table
					minW="100%"
					data={store._items}
					headers={pageStrings.header}
					renderRow={(item) => (
						<Tr key={item.id}>
							<TableCellWithActionButtons
								onEdit={() => onGoToEditAdminUser(item.id)}
								onDelete={() => openDialog(item)}
							/>
							<Td>
								<Text>{item.name}</Text>
							</Td>
							<Td>
								<Text>{item.email}</Text>
							</Td>
						</Tr>
					)}
					loading={store._loading}
					emptyMessage={strings.common.noResults}
					currentPage={store._page}
					prevPage={store.previousPage}
					nextPage={store.nextPage}
					isHaveNextPage={store._isHaveNextPage}
					onAdd={onGoToCreateAdminUser}
				/>
			</PageContent>
		</Page>
	);
};

export default observer(TableView);
