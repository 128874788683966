import { ComponentStyleConfig } from "@chakra-ui/react";
import spacing from "../../spacing";

const { s2 } = spacing;

export const Input: ComponentStyleConfig = {
	defaultProps: {
		variant: "filled",
	},
	variants: {
		filled: {
			field: {
				color: "primary.800",
				bg: "gray.100",
				_hover: {
					bg: "gray.200",
				},
			},
		},
		alwaysWhite: {
			field: {
				color: "primary.800",
				bg: "white",
				_hover: {
					bg: "white",
				},
				_focus: {
					bg: "white",
					borderColor: "primary.700",
					borderWidth: s2,
				},
			},
		},
	},
};
