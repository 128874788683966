import React from "react";
import {
	Tr,
	Td,
	Text,
} from "@chakra-ui/react";


import strings from "~/resources/strings";
import api from "~/resources/api";
import moment from "moment";

interface IProps{
	data: api.Batch;
	onGoToDetails: (id: string) => void;
	showUserCreated?: boolean;
}

export const TableRowBatch: React.FC<IProps> = (props: IProps) => {
	const { data, onGoToDetails, showUserCreated } = props;

	return (
		<Tr key={data.id}>
			<Td>
				<Text>
					{api.translateBatchStatus(data.status)}
				</Text>
			</Td>
			{data.userReceiver && (
				<Td>
					<Text variant="link" onClick={() => onGoToDetails(data.id)}>
						{data.userReceiver.name}
					</Text>
				</Td>
			)}
			{showUserCreated && (
				<Td>
					<Text variant="link" onClick={() => onGoToDetails(data.id)}>
						{data.userCreator.name}
					</Text>
				</Td>
			)}
			<Td>
				<Text>{data.code}</Text>
			</Td>
			<Td>
				<Text>{moment(data.createdAt).format(strings.moment.date)}</Text>
			</Td>
		</Tr>
	);
};
