import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Card } from "../Card";
import { Label } from "../Label";
import strings from "../../resources/strings";
import spacing from "../../themes/spacing";

const { s4 } = spacing;

export interface IUserInfoCardProps {
	supplier: string;
	code: string;
	email: string;
}

const componentStrings = strings.components.userInfoCard;

export const UserInfoCard: React.FC<IUserInfoCardProps> = (props) => {
	const { supplier, code, email } = props;
	return (
		<Card
			w="100%"
			flexDir={{base: "column", md: "column", lg: "row"}}
			justifyContent="flex-start"
			alignItems="flex-start"
		>
			<Flex
				flexDir="column"
				alignItems="flex-start"
				mb={{base: s4, md: 0}}
				mr={{base: 0, md: "auto"}}
			>
				<Label size="lg" fontWeight="700">
					{componentStrings.supplier}
				</Label>
				<Text size="lg" wordBreak="break-word">{supplier}</Text>
			</Flex>
			<Flex
				flexDir="column"
				alignItems="flex-start"
				mb={{base: s4, md: 0}}
				mr={{base: 0, md: "auto"}}
			>
				<Label size="lg" fontWeight="700">
					{componentStrings.code}
				</Label>
				<Text size="lg" wordBreak="break-word">{code}</Text>
			</Flex>
			<Flex
				flexDir="column"
				alignItems="flex-start"
			>
				<Label size="lg" fontWeight="700">
					{componentStrings.email}
				</Label>
				<Text size="lg" wordBreak="break-word">{email}</Text>
			</Flex>
		</Card>
	);
};
