import React from "react";
import {
	Flex,
	Heading,
	VStack,
	Text,
} from "@chakra-ui/react";
import { Loading, Pagination } from "~/components";
import spacing from "~/themes/spacing";
import api from "~/resources/api";

export interface BatchListProps {
	data: api.Batch[];
	onSelectItem: (item: api.Batch) => void;
	loading: boolean;
	emptyMessage: string;
	currentPage: number;
	prevPage: () => void;
	nextPage: () => void;
	isHaveNextPage: boolean;
}

interface IProps extends BatchListProps {
	searchValue: string | null;
	onChangeSearchValue: (value: string) => void;
}

export const BatchList: React.FC<IProps> = (props) => {
	const {
		data,
		loading,
		emptyMessage,
		onSelectItem,
		currentPage,
		prevPage,
		nextPage,
		searchValue,
		onChangeSearchValue,
		isHaveNextPage,
	} = props;

	return (
		loading || !data ? (
			<Flex
				my={spacing.s5}
				w={"100%"}
				minH={300}
				justifyContent="center"
			>
				<Loading />
			</Flex>
		) : (
			<>
				{data.length < 1
					? (
						<Flex
							my={spacing.s5}
							w="100%"
							justifyContent="center"
							minH={150}
							alignItems="center"
						>
							<Heading size="md" fontWeight="bold">{emptyMessage}</Heading>
						</Flex>
					) : (
						<VStack
							align="stretch"
							maxH={{ base: 150, md: 300 }}
							overflowY={"auto"}
							borderBottomColor="primary.500"
							borderBottomWidth={spacing.s2}
							borderTopColor="primary.500"
							borderTopWidth={spacing.s2}
						>
							{data.map((item, index) => (
								<Text
									key={index}
									px={spacing.s4}
									py={spacing.s2}
									onClick={() => {
										onChangeSearchValue(item.code);
										onSelectItem(item);
									}}
									_hover={{ bgColor: "gray.100" }}
									bgColor={searchValue === item.code ? "gray.100" : "initial"}
								>
									{item.code}
								</Text>
							))}
						</VStack>
					)}
				<Flex
					mt={spacing.s4}
					px={spacing.s6}
					alignItems="center"
					justifyContent="center"
				>
					<Pagination
						currentPage={currentPage}
						prevPage={prevPage}
						nextPage={nextPage}
						isHaveNextPage={isHaveNextPage}
					/>
				</Flex>
			</>
		)
	);
};
