import { action, computed, makeObservable, observable, runInAction } from "mobx";
import {
	FormShelf,
	LoaderShelf,
	AttributeShelf,
} from "@startapp/mobx-utils";
import PaginatedListStore from "~/stores/PaginatedListStore";

import { IProductFormData } from "~/components/ProductFormModal";
import api from "~/resources/api";

import ImagePickerShelf from "~/shelves/ImagePicker";
import { IRouterPusher } from "~/interfaces/IRouter";
import { Errors, showErrorToast, showSuccessToast, strings } from "~/resources";

const pageStrings = strings.productShelf;
export default class ProductsShelf extends PaginatedListStore<api.Product> {
	public productEditId: string | null = null;
	public batch: api.Batch | null = null;
	public loader = new LoaderShelf();
	public productForm = new FormShelf<IProductFormData>({
		title: "",
		code: "",
	});
	public firstImage = new ImagePickerShelf();
	public secondImage = new ImagePickerShelf();
	public thirdImage = new ImagePickerShelf();

	public suggestedPrice = "";
	public selectedBrand = "";
	public selectedSize = new AttributeShelf<api.Size | null >(null);
	public sizes = api.Size.sXS;

	public totalPages = 1;

	private getProductsFromBatch: (page: number) => Promise<api.ProductsWithPaginationInfo>;

	constructor(router: IRouterPusher, getProductsFromBatch: (page: number) => Promise<api.ProductsWithPaginationInfo>) {
		super(router);
		this.getProductsFromBatch = getProductsFromBatch;
		makeObservable(this, {
			productEditId: observable,
			batch: observable,
			firstImage: observable,
			secondImage: observable,
			thirdImage: observable,
			suggestedPrice: observable,
			selectedBrand: observable,
			sizes: observable,
			totalPages: observable,
			imagesToPicker: computed,
			uploadedImages: computed,
			onChangeSuggestedPrice: action,
			clearFields: action,
			setBatch: action,
			setSelectedBrand: action,
			hasNextPage: computed,
			setProductData: action,
			setProductEditId: action,
			addOrEditProduct: action,
		});
		this.fetchPage(0);
	}

	public get hasNextPage(): boolean {
		const currentPage = this._page + 1;
		const lastPage = this.totalPages;
		return lastPage > currentPage;
	}

	protected async getDataItemsPerPage(page: number): Promise<api.Product[]> {
		const { items, totalPages } = await this.getProductsFromBatch(page);
		runInAction(() => {
			this.totalPages = totalPages;
		});
		return items;
	}

	public setProductEditId = (id: string) => runInAction(() => this.productEditId = id);

	public setSelectedSize = (selected: api.Size) => runInAction(()=>this.selectedSize.setValue(selected));

	public setProductData = (data: api.Product) => {
		this.productForm = new FormShelf<IProductFormData>({
			title: data.title || "",
			code: data.code || "",
		});
		this.selectedSize.setValue(data.size);
		this.suggestedPrice = data.suggestedValue.toString();
		this.selectedBrand = data.brand;
		if(data.images.length > 0){
			this.firstImage.getPickerFields().setUploadedImage(data.images[0]);
		}
		if(data.images.length > 1){
			this.secondImage.getPickerFields().setUploadedImage(data.images[1]);
		}
		if(data.images.length > 2){
			this.thirdImage.getPickerFields().setUploadedImage(data.images[2]);
		}

		this.setProductEditId(data.id);
	};

	public get imagesToPicker() {
		return [
			this.firstImage.getPickerFields(),
			this.secondImage.getPickerFields(),
			this.thirdImage.getPickerFields(),
		];
	}

	public get uploadedImages() {
		const images: api.UncertainImage[] = [];
		const imagePickers = [
			this.firstImage,
			this.secondImage,
			this.thirdImage,
		];
		imagePickers.forEach((imagePicker) => {
			if (imagePicker.uncertainfiedImage) {
				images.push(imagePicker.uncertainfiedImage);
			}
		});
		return images;
	}

	public setBatch = (batch: api.Batch) => this.batch = batch;

	public setSelectedBrand = (value: string) => runInAction(()=>this.selectedBrand = value);

	public onChangeSuggestedPrice = (value: string) => {
		this.suggestedPrice = value;
	};

	public clearFields = () => {
		this.productForm = new FormShelf<IProductFormData>({
			title: "",
			code: "",
		});
		this.selectedSize.setValue(null);
		this.firstImage = new ImagePickerShelf();
		this.secondImage = new ImagePickerShelf();
		this.thirdImage = new ImagePickerShelf();
		this.suggestedPrice = "";
		this.selectedBrand = "";
		this.productEditId = null;
	};

	public addOrEditProduct = async (onSuccess?: () => void) => {
		this.loader.tryStart();
		try {
			if (!this.batch) {
				throw pageStrings.error.needSelected;
			}

			const formValues = this.productForm.getValues();

			const uploadedImages = this.uploadedImages;

			if(this.productEditId){
				const editProductData: api.EditProduct = {
					...formValues,
					brand: this.selectedBrand,
					code: formValues.code || null,
					title: formValues.title || null,
					size: this.selectedSize.value || null,
					images: uploadedImages,
					suggestedValue: parseInt(this.suggestedPrice),
				};
				await api.editProduct(
					this.productEditId,
					this.batch.id,
					editProductData,
				);
				showSuccessToast(pageStrings.success.productEdited);
			}else{
				const productData: api.NewProduct = {
					...formValues,
					brand: this.selectedBrand,
					code: formValues.code || null,
					title: formValues.title || null,
					size:  this.selectedSize.value || null,
					images: uploadedImages,
					suggestedValue: parseInt(this.suggestedPrice),
				};
				await api.addProductInBatch(this.batch.id, productData);
				showSuccessToast(pageStrings.added);
			}

			this.clearFields();
			this.refresh();
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public removeProduct = async (productId: string) => {
		this.loader.tryStart();
		try {
			if (!this.batch) {
				throw pageStrings.error.needSelected;
			}
			if (this.batch.status !== api.BatchStatus.creating) {
				throw pageStrings.error.batchAlreadySent;
			}
			await api.removeProduct(productId);
			this.refresh();
			showSuccessToast(pageStrings.success.productRemoved);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public getProductProps = () => (
		{
			suggestedPrice: this.suggestedPrice,
			formShelf: this.productForm,
			images: this.imagesToPicker,
			selectedBrand: this.selectedBrand,
			selectedSize: this.selectedSize.value,
			onSelectSize: this.setSelectedSize,
			onChangeSuggestedPrice: this.onChangeSuggestedPrice,
		}
	);

	public getListProps = () => ({
		loading: this._loading,
		currentPage: this._page,
		totalPages: this.totalPages,
		isHaveNextPage: this.hasNextPage,
		prevPage: this.previousPage,
		nextPage: this.nextPage,
	});
}
