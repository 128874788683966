import React from "react";
import { Box } from "@chakra-ui/react";
import { Navbar, INavbarProps } from "../../layout/Navbar";
import { observer } from "mobx-react-lite";

export const MainLayout: React.FC<INavbarProps> = observer((props) => {
	const { children, countOfNewNotifications, onClickRing } = props;

	return (
		<Box minH="100vh">
			<Navbar
				countOfNewNotifications={countOfNewNotifications}
				onClickRing={onClickRing}
			/>
			{children}
		</Box>
	);
});
