import React from "react";
import { observer } from "mobx-react-lite";
import { SearchListProps } from "./SearchList";
import { SearchModal } from "./SearchModal";
import { useDisclosure } from "@chakra-ui/react";
import { SearchInput } from "..";
import strings from "~/resources/strings";

interface IProps {
	searchValue: string;
	onClickSearch: () => void;
	onChangeSearchValue: (value: string) => void;
	onAdd: () => void;
	onBack: () => void;
	tableProps: SearchListProps;
	disabled?: boolean;
	closeOnOverlayClick?: boolean;
}
export const SearchSupplierModal: React.FC<IProps> = observer((props) => {
	const {
		searchValue,
		onClickSearch,
		onChangeSearchValue,
		onAdd,
		onBack,
		tableProps,
		disabled,
		closeOnOverlayClick,
	} = props;

	const {
		isOpen: isSearchModalOpen,
		onOpen: openSearchModal,
		onClose: closeSearchModal,
	} = useDisclosure();

	const pageStrings = strings.components.searchSupplierModal;

	return (
		<>
			<SearchInput
				isFilled
				name="supplierSearch"
				value={searchValue}
				onChange={()=>{}}
				onClickSearch={disabled ? () => {} : openSearchModal}
				placeholder={pageStrings.placeholder.searchSupplier}
				isReadOnly
				containerProps={{ w: ["100%", 512], onClick: disabled ? () => {} : openSearchModal}}
			/>

			<SearchModal
				searchValue={searchValue}
				closeOnOverlayClick={closeOnOverlayClick}
				onClickSearch={disabled ? () => {} : onClickSearch}
				onChangeSearchValue={onChangeSearchValue}
				isOpen={isSearchModalOpen}
				onClose={closeSearchModal}
				tableProps={tableProps}
				onAdd={onAdd}
				onBack={onBack}
			/>
		</>
	);

});

