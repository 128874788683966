import { IRouterPusher } from "~/interfaces/IRouter";
import api from "~/resources/api";
import AuthStore from "~/stores/AuthStore";
import PaginatedListStore from "~/stores/PaginatedListStore";

export default class Store extends PaginatedListStore<api.Batch> {
	public authStore: AuthStore;

	constructor(router: IRouterPusher, authStore: AuthStore) {
		super(router);
		this.authStore = authStore;
		this.fetchPage(0);
	}

	protected async getDataItemsPerPage(page: number): Promise<api.Batch[]> {
		return api.getAllBatchesCreatedBySupplier(page, null);
	}
}
