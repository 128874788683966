import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import DetailsView from "./Details";
import CreateOrEditSupplier from "./CreateOrEditSupplier";

const Supplier: React.FC = () => {
	return (
		<Switch>
			<Route
				exact
				path="/dashboard/suppliers"
				component={Table}
			/>
			<Route
				path="/dashboard/suppliers/create/"
				component={CreateOrEditSupplier}
			/>
			<Route
				path="/dashboard/suppliers/edit/:id"
				component={CreateOrEditSupplier}
			/>
			<Route
				path="/dashboard/suppliers/details/:id"
				component={DetailsView}
			/>
		</Switch>
	);
};

export default observer(Supplier);
