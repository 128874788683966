import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Heading } from "@chakra-ui/react";
import {
	Page,
	PageContent,
	Table,
	TableRowBatch,
} from "~/components";
import { strings, api } from "~/resources";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import spacing from "~/themes/spacing";
import Store from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.supplierBatches.table;
	const commonBatchesStrings = strings.commonBatches.table;
	const { authStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(history, authStore));

	const onGoToDetails = (id: string) => {
		history.push(`supplierBatches/details/${id}`);
	};

	const onGoToCreate = (id?: string) => {
		history.push(`supplierBatches/create${id ? `/${id}` : ""}`);
	};

	return (
		<Page>
			<PageContent>
				<Heading
					variant="secondary"
					size="sm"
					color="primary.400"
					mb={spacing.s3}
				>
					{pageStrings.title}
				</Heading>

				<Table
					minW="100%"
					data={store._items}
					headers={commonBatchesStrings.header}
					renderRow={(item) => (
						<TableRowBatch
							key={item.id}
							data={item}
							onGoToDetails={
								(item.status === api.BatchStatus.creating) && !!authStore.currentAdminUser?.code
									? onGoToCreate
									: onGoToDetails
							}
							showUserCreated
						/>
					)}
					loading={store._loading}
					emptyMessage={strings.common.noResults}
					currentPage={store._page}
					prevPage={store.previousPage}
					nextPage={store.nextPage}
					isHaveNextPage={store._isHaveNextPage}
					onAdd={
						authStore.currentAdminUser && authStore.currentAdminUser.code ? () => onGoToCreate() : undefined
					}
				/>
			</PageContent>
		</Page>
	);
};

export default observer(TableView);
