import React from "react";
import { observer } from "mobx-react-lite";

import {
	ModalHeader,
	Button,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
} from "@chakra-ui/react";
import spacing from "../../themes/spacing";
import { strings } from "~/resources";

export interface IProps  {
	isOpen: boolean;
	title?: string;
	closeOnOverlayClick?: boolean;
	onAccept: () => void;
	onClose: () => void;
}

export const TermsOfUseModal: React.FC<IProps> = observer((props) => {
	const {
		onAccept,
		onClose,
		isOpen,
		closeOnOverlayClick,
	} = props;

	const componentStrings = strings.components.termsOfUseModal;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
			size="4xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader color="primary.500">{componentStrings.title}</ModalHeader>
				<ModalBody color="primary.500">
					{componentStrings.headerDescription.map((value, index)=>(
						<Text key={`${value[0]}${index}`} textAlign="center">
							{value}
						</Text>
					))}
					{componentStrings.description.map((value, index)=>(
						<Text key={`${index}${value[0]}`} my={spacing.s2}>
							{value}
						</Text>
					))}
				</ModalBody>

				<ModalFooter>
					<Button
						color="white"
						borderColor="primary.500"
						bg="primary.500"
						_hover={{ bg: "primary.600" }}
						mx={{ base: spacing.s2, md: spacing.s10 }}
						onClick={onAccept}
					>
						{strings.actions.confirm}
					</Button>
					<Button
						variant="outline"
						borderColor="primary.500"
						color="primary.500"
						mx={{ base: spacing.s2, md: spacing.s10 }}
						onClick={onClose}
					>
						{strings.actions.back}
					</Button>

				</ModalFooter>
			</ModalContent>
		</Modal>
	);

});

