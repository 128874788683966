import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import spacing from "~/themes/spacing";

interface IProps extends FlexProps{

}

export const Page: React.FC<IProps> = (props) => {
	const {
		children,
		...rest
	} = props;
	return(
		<Flex
			w="100%"
			minH="92.35vh"
			justifyContent="center"
			bg="gray.200"
			py={spacing.s8}
			{...rest}
		>
			{children}
		</Flex>
	);
};
