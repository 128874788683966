import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Text, BoxProps } from "@chakra-ui/react";
import { Card, Label } from "~/components";
import spacing from "~/themes/spacing";
import strings from "~/resources/strings";

interface IProps {
	producstNumber: number;
	pagesNumber: number;
	boxProps: BoxProps;
}

export const TotalProductsCard: React.FC<IProps> = observer((props) => {
	const { producstNumber, pagesNumber, boxProps } = props;

	const componentStrings = strings.components.totalProductsCard;

	return (
		<Card
			py={spacing.s4}
			w={{ base: "100%", sm: "fit-content" }}
			minW={{ base: "100%", sm: 350 }}
			flexDir="row"
			flexWrap="wrap"
			justifyContent="flex-start"
			mb={spacing.s10}
			{...boxProps}
		>
			<Flex mr="auto" pr={spacing.s2} >
				<Label size="sm" fontWeight="700">
					{componentStrings.totalProductsNumber}
				</Label>
				<Text size="sm" ml={spacing.s1}>{producstNumber}</Text>
			</Flex>
			<Flex >
				<Label size="sm" fontWeight="700">
					{componentStrings.totalPages}
				</Label>
				<Text size="sm" ml={spacing.s1}>{pagesNumber}</Text>
			</Flex>
		</Card>
	);
});
