import React from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { Text, useBreakpointValue, BoxProps } from "@chakra-ui/react";
import { SearchIcon, Icon } from "@chakra-ui/icons";
import { IoNotificationsSharp } from "react-icons/io5";
import spacing from "~/themes/spacing";
import strings from "~/resources/strings";
import { Card } from "../Card";
import { api } from "~/resources";

interface IProps {
	text: string;
	onClick: () => void;
	createdAt?: Date;
	notificationStatus: api.NotificationType;
}

export const NotificationCard: React.FC<IProps> = observer((props) => {
	const { text, onClick, createdAt, notificationStatus } = props;
	const shouldShowSearchIcon = useBreakpointValue({ base: false, md: true });

	const setCardColor = (): BoxProps["color"] => {
		switch(notificationStatus) {
			case api.NotificationType.accepted:
				return "green.400";
			case api.NotificationType.refused:
				return "red.500";
			case api.NotificationType.changed:
			default:
				return "yellow.500";
		}
	};

	return (
		<Card
			onClick={onClick}
			flexDirection="row"
			w="100%"
			maxW="100vw"
			minH="60px"
			cursor="pointer"
			mb={spacing.s8}
			alignItems="center"
			position="relative"
			bg={setCardColor()}
			px={{ base: spacing.s3, sm: spacing.s6 }}
		>
			<Icon
				as={IoNotificationsSharp}
				w={{ base: 6, sm: 7 }}
				h={{ base: 6, sm: 7 }}
				mr={spacing.s4}
				color="white"
			/>
			<Text
				fontSize={{ base: "sm", sm: "md", md: "lg" }}
				mr="auto"
				color="white"
			>
				{text}
			</Text>

			<Text
				position="absolute"
				right={5}
				bottom={0}
				fontSize={{ base: "sm", md: "16px"}}
				color="white"
			>
				{strings.home.notifications.at(moment(createdAt).format("HH:mm, DD/MM/YYYY"))}
			</Text>
			{shouldShowSearchIcon && (
				<SearchIcon
					w={{ base: 6, sm: 7 }}
					h={{ base: 6, sm: 7 }}
					color="white"
				/>
			)}
		</Card>
	);
});
