import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
    latLng: LatLng | null;
}

export interface ProductReview {
    id: string;
    isAccepted: boolean;
    newSuggestedValue: number | null;
}

export interface FilterProduct {
    acceptedAt: Date | null;
    batch: string | null;
}

export interface FilterBatches {
    supplierName: string | null;
    supplierCode: string | null;
}

export interface FilterBrands {
    name: string | null;
}

export interface ProductCsvData {
    code: string | null;
    brand: string;
    supplierName: string | null;
    price: number;
}

export interface FilterByPeriod {
    startDate: Date | null;
    endDate: Date | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface AdminUser {
    id: string;
    name: string;
    email: string;
    code: string | null;
    acceptedTermsOfUse: boolean;
    createdAt: Date;
}

export interface NewAdminUser {
    name: string;
    email: string;
    code: string | null;
    password: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
    code: string | null;
}

export interface NewProduct {
    images: UncertainImage[];
    code: string | null;
    title: string | null;
    brand: string;
    size: Size | null;
    suggestedValue: number;
}

export interface EditProduct {
    images: UncertainImage[];
    code: string | null;
    title: string | null;
    brand: string;
    size: Size | null;
    suggestedValue: number;
}

export interface PriceSuggestion {
    userId: string;
    value: number;
    createdAt: Date;
}

export interface Product {
    id: string;
    images: Image[];
    code: string | null;
    title: string | null;
    brand: string;
    size: Size | null;
    acceptedAt: Date | null;
    suggestedValue: number;
    lastProposedPrice: number;
    createdAt: Date;
}

export interface ProductsWithPaginationInfo {
    items: Product[];
    totalPages: number;
}

export interface Batch {
    id: string;
    userCreator: AdminUser;
    userReceiver: AdminUser | null;
    code: string;
    createdAt: Date;
    status: BatchStatus;
}

export interface Brand {
    id: string;
    name: string;
    createdAt: Date;
}

export interface Notification {
    id: string;
    userReceiver: AdminUser | null;
    batch: Batch;
    isRead: boolean;
    wasViewed: boolean;
    status: NotificationType;
    createdAt: Date;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum BatchStatus {
    creating = "creating",
    pending = "pending",
    pendingAdminReview = "pendingAdminReview",
    pendingSupplierReview = "pendingSupplierReview",
    reviewed = "reviewed",
}

export function translateBatchStatus(enumBatchStatus: BatchStatus): string {
    switch (enumBatchStatus) {
        case BatchStatus.creating: {
            return strings ? strings["enum"]["BatchStatus"]["creating"] || BatchStatus.creating : BatchStatus.creating;
        }
        case BatchStatus.pending: {
            return strings ? strings["enum"]["BatchStatus"]["pending"] || BatchStatus.pending : BatchStatus.pending;
        }
        case BatchStatus.pendingAdminReview: {
            return strings ? strings["enum"]["BatchStatus"]["pendingAdminReview"] || BatchStatus.pendingAdminReview : BatchStatus.pendingAdminReview;
        }
        case BatchStatus.pendingSupplierReview: {
            return strings ? strings["enum"]["BatchStatus"]["pendingSupplierReview"] || BatchStatus.pendingSupplierReview : BatchStatus.pendingSupplierReview;
        }
        case BatchStatus.reviewed: {
            return strings ? strings["enum"]["BatchStatus"]["reviewed"] || BatchStatus.reviewed : BatchStatus.reviewed;
        }
    }
    return "";
}

export function allValuesBatchStatus(): BatchStatus[] {
    return [
        BatchStatus.creating,
        BatchStatus.pending,
        BatchStatus.pendingAdminReview,
        BatchStatus.pendingSupplierReview,
        BatchStatus.reviewed,
    ];
}

export function allTranslatedValuesBatchStatus(): string[] {
    return [
        translateBatchStatus(BatchStatus.creating),
        translateBatchStatus(BatchStatus.pending),
        translateBatchStatus(BatchStatus.pendingAdminReview),
        translateBatchStatus(BatchStatus.pendingSupplierReview),
        translateBatchStatus(BatchStatus.reviewed),
    ];
}

export function allDisplayableValuesBatchStatus(): string[] {
    return allTranslatedValuesBatchStatus().sort();
}

export function valueFromTranslationBatchStatus(translation: string): BatchStatus {
    const index = allTranslatedValuesBatchStatus().indexOf(translation);
    return allValuesBatchStatus()[index] || BatchStatus.creating;
}

export enum NotificationType {
    created = "created",
    reviewed = "reviewed",
    accepted = "accepted",
    refused = "refused",
    changed = "changed",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.created: {
            return strings ? strings["enum"]["NotificationType"]["created"] || NotificationType.created : NotificationType.created;
        }
        case NotificationType.reviewed: {
            return strings ? strings["enum"]["NotificationType"]["reviewed"] || NotificationType.reviewed : NotificationType.reviewed;
        }
        case NotificationType.accepted: {
            return strings ? strings["enum"]["NotificationType"]["accepted"] || NotificationType.accepted : NotificationType.accepted;
        }
        case NotificationType.refused: {
            return strings ? strings["enum"]["NotificationType"]["refused"] || NotificationType.refused : NotificationType.refused;
        }
        case NotificationType.changed: {
            return strings ? strings["enum"]["NotificationType"]["changed"] || NotificationType.changed : NotificationType.changed;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.created,
        NotificationType.reviewed,
        NotificationType.accepted,
        NotificationType.refused,
        NotificationType.changed,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.created),
        translateNotificationType(NotificationType.reviewed),
        translateNotificationType(NotificationType.accepted),
        translateNotificationType(NotificationType.refused),
        translateNotificationType(NotificationType.changed),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.created;
}

export enum Size {
    sXS = "sXS",
    sS = "sS",
    sM = "sM",
    sL = "sL",
    s34 = "s34",
    s35 = "s35",
    s36 = "s36",
    s37 = "s37",
    s38 = "s38",
    s39 = "s39",
    s40 = "s40",
    s41 = "s41",
    s42 = "s42",
    s43 = "s43",
    s44 = "s44",
    s45 = "s45",
    s46 = "s46",
}

export function translateSize(enumSize: Size): string {
    switch (enumSize) {
        case Size.sXS: {
            return strings ? strings["enum"]["Size"]["sXS"] || Size.sXS : Size.sXS;
        }
        case Size.sS: {
            return strings ? strings["enum"]["Size"]["sS"] || Size.sS : Size.sS;
        }
        case Size.sM: {
            return strings ? strings["enum"]["Size"]["sM"] || Size.sM : Size.sM;
        }
        case Size.sL: {
            return strings ? strings["enum"]["Size"]["sL"] || Size.sL : Size.sL;
        }
        case Size.s34: {
            return strings ? strings["enum"]["Size"]["s34"] || Size.s34 : Size.s34;
        }
        case Size.s35: {
            return strings ? strings["enum"]["Size"]["s35"] || Size.s35 : Size.s35;
        }
        case Size.s36: {
            return strings ? strings["enum"]["Size"]["s36"] || Size.s36 : Size.s36;
        }
        case Size.s37: {
            return strings ? strings["enum"]["Size"]["s37"] || Size.s37 : Size.s37;
        }
        case Size.s38: {
            return strings ? strings["enum"]["Size"]["s38"] || Size.s38 : Size.s38;
        }
        case Size.s39: {
            return strings ? strings["enum"]["Size"]["s39"] || Size.s39 : Size.s39;
        }
        case Size.s40: {
            return strings ? strings["enum"]["Size"]["s40"] || Size.s40 : Size.s40;
        }
        case Size.s41: {
            return strings ? strings["enum"]["Size"]["s41"] || Size.s41 : Size.s41;
        }
        case Size.s42: {
            return strings ? strings["enum"]["Size"]["s42"] || Size.s42 : Size.s42;
        }
        case Size.s43: {
            return strings ? strings["enum"]["Size"]["s43"] || Size.s43 : Size.s43;
        }
        case Size.s44: {
            return strings ? strings["enum"]["Size"]["s44"] || Size.s44 : Size.s44;
        }
        case Size.s45: {
            return strings ? strings["enum"]["Size"]["s45"] || Size.s45 : Size.s45;
        }
        case Size.s46: {
            return strings ? strings["enum"]["Size"]["s46"] || Size.s46 : Size.s46;
        }
    }
    return "";
}

export function allValuesSize(): Size[] {
    return [
        Size.sXS,
        Size.sS,
        Size.sM,
        Size.sL,
        Size.s34,
        Size.s35,
        Size.s36,
        Size.s37,
        Size.s38,
        Size.s39,
        Size.s40,
        Size.s41,
        Size.s42,
        Size.s43,
        Size.s44,
        Size.s45,
        Size.s46,
    ];
}

export function allTranslatedValuesSize(): string[] {
    return [
        translateSize(Size.sXS),
        translateSize(Size.sS),
        translateSize(Size.sM),
        translateSize(Size.sL),
        translateSize(Size.s34),
        translateSize(Size.s35),
        translateSize(Size.s36),
        translateSize(Size.s37),
        translateSize(Size.s38),
        translateSize(Size.s39),
        translateSize(Size.s40),
        translateSize(Size.s41),
        translateSize(Size.s42),
        translateSize(Size.s43),
        translateSize(Size.s44),
        translateSize(Size.s45),
        translateSize(Size.s46),
    ];
}

export function allDisplayableValuesSize(): string[] {
    return allTranslatedValuesSize().sort();
}

export function valueFromTranslationSize(translation: string): Size {
    const index = allTranslatedValuesSize().indexOf(translation);
    return allValuesSize()[index] || Size.sXS;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    InvalidAction = "InvalidAction",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NameAlreadyRegistered = "NameAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    IsNotSupplier = "IsNotSupplier",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.InvalidAction: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAction"] || ErrorType.InvalidAction : ErrorType.InvalidAction;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NameAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NameAlreadyRegistered"] || ErrorType.NameAlreadyRegistered : ErrorType.NameAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.IsNotSupplier: {
            return strings ? strings["enum"]["ErrorType"]["IsNotSupplier"] || ErrorType.IsNotSupplier : ErrorType.IsNotSupplier;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.InvalidAction,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NameAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.IsNotSupplier,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.InvalidAction),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NameAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.IsNotSupplier),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        code: e.code === null || e.code === undefined ? null : e.code,
        acceptedTermsOfUse: !!e.acceptedTermsOfUse,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllSuppliers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllSuppliers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        code: e.code === null || e.code === undefined ? null : e.code,
        acceptedTermsOfUse: !!e.acceptedTermsOfUse,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getSuppliersByName(name: string, pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        name: name,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getSuppliersByName", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        code: e.code === null || e.code === undefined ? null : e.code,
        acceptedTermsOfUse: !!e.acceptedTermsOfUse,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getSuppliersByCode(code: string, pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        code: code,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getSuppliersByCode", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        code: e.code === null || e.code === undefined ? null : e.code,
        acceptedTermsOfUse: !!e.acceptedTermsOfUse,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function acceptTermsOfUse(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "acceptTermsOfUse", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            name: newAdminUser.name,
            email: newAdminUser.email,
            code: newAdminUser.code === null || newAdminUser.code === undefined ? null : newAdminUser.code,
            password: newAdminUser.password,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            code: editAdminUser.code === null || editAdminUser.code === undefined ? null : editAdminUser.code,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        acceptedTermsOfUse: !!ret.acceptedTermsOfUse,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getProductsNumberByBatch(batchId: string, progress?: (progress: number) => void): Promise<number> {
    const args = {
        batchId: batchId,
    };
    const ret = await makeRequest({name: "getProductsNumberByBatch", args, progress});
    return ret || 0;
}

export async function getProductsByBatch(batchId: string, pageOffset: number, progress?: (progress: number) => void): Promise<ProductsWithPaginationInfo> {
    const args = {
        batchId: batchId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getProductsByBatch", args, progress});
    return {
        items: ret.items.map((e: any) => ({
            id: e.id,
            images: e.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            code: e.code === null || e.code === undefined ? null : e.code,
            title: e.title === null || e.title === undefined ? null : e.title,
            brand: e.brand,
            size: e.size === null || e.size === undefined ? null : e.size,
            acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(parseInt(e.acceptedAt.split("-")[0], 10), parseInt(e.acceptedAt.split("-")[1], 10) - 1, parseInt(e.acceptedAt.split("-")[2], 10)),
            suggestedValue: e.suggestedValue || 0,
            lastProposedPrice: e.lastProposedPrice || 0,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        totalPages: ret.totalPages || 0,
    };
}

export async function getProductsByDate(date: Date, pageOffset: number, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        date: typeof(date) === "string" ? new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getProductsByDate", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: e.code === null || e.code === undefined ? null : e.code,
        title: e.title === null || e.title === undefined ? null : e.title,
        brand: e.brand,
        size: e.size === null || e.size === undefined ? null : e.size,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(parseInt(e.acceptedAt.split("-")[0], 10), parseInt(e.acceptedAt.split("-")[1], 10) - 1, parseInt(e.acceptedAt.split("-")[2], 10)),
        suggestedValue: e.suggestedValue || 0,
        lastProposedPrice: e.lastProposedPrice || 0,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getSupplierApprovedProducts(supplierId: string, filter: FilterProduct | null, pageOffset: number, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        supplierId: supplierId,
        filter: filter === null || filter === undefined ? null : {
            acceptedAt: filter.acceptedAt === null || filter.acceptedAt === undefined ? null : (typeof filter.acceptedAt === "string" && (filter.acceptedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.acceptedAt as any).replace("Z", "") : filter.acceptedAt.toISOString().replace("Z", "")),
            batch: filter.batch === null || filter.batch === undefined ? null : filter.batch,
        },
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getSupplierApprovedProducts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: e.code === null || e.code === undefined ? null : e.code,
        title: e.title === null || e.title === undefined ? null : e.title,
        brand: e.brand,
        size: e.size === null || e.size === undefined ? null : e.size,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(parseInt(e.acceptedAt.split("-")[0], 10), parseInt(e.acceptedAt.split("-")[1], 10) - 1, parseInt(e.acceptedAt.split("-")[2], 10)),
        suggestedValue: e.suggestedValue || 0,
        lastProposedPrice: e.lastProposedPrice || 0,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getGetSupplierApprovedProductsWithoutPagination(supplierId: string, filter: FilterProduct | null, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        supplierId: supplierId,
        filter: filter === null || filter === undefined ? null : {
            acceptedAt: filter.acceptedAt === null || filter.acceptedAt === undefined ? null : (typeof filter.acceptedAt === "string" && (filter.acceptedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.acceptedAt as any).replace("Z", "") : filter.acceptedAt.toISOString().replace("Z", "")),
            batch: filter.batch === null || filter.batch === undefined ? null : filter.batch,
        },
    };
    const ret = await makeRequest({name: "getGetSupplierApprovedProductsWithoutPagination", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: e.code === null || e.code === undefined ? null : e.code,
        title: e.title === null || e.title === undefined ? null : e.title,
        brand: e.brand,
        size: e.size === null || e.size === undefined ? null : e.size,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(parseInt(e.acceptedAt.split("-")[0], 10), parseInt(e.acceptedAt.split("-")[1], 10) - 1, parseInt(e.acceptedAt.split("-")[2], 10)),
        suggestedValue: e.suggestedValue || 0,
        lastProposedPrice: e.lastProposedPrice || 0,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function addProductInBatch(batchId: string, newProduct: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        batchId: batchId,
        newProduct: {
            images: newProduct.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            code: newProduct.code === null || newProduct.code === undefined ? null : newProduct.code,
            title: newProduct.title === null || newProduct.title === undefined ? null : newProduct.title,
            brand: newProduct.brand,
            size: newProduct.size === null || newProduct.size === undefined ? null : newProduct.size,
            suggestedValue: newProduct.suggestedValue || 0,
        },
    };
    const ret = await makeRequest({name: "addProductInBatch", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        title: ret.title === null || ret.title === undefined ? null : ret.title,
        brand: ret.brand,
        size: ret.size === null || ret.size === undefined ? null : ret.size,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(parseInt(ret.acceptedAt.split("-")[0], 10), parseInt(ret.acceptedAt.split("-")[1], 10) - 1, parseInt(ret.acceptedAt.split("-")[2], 10)),
        suggestedValue: ret.suggestedValue || 0,
        lastProposedPrice: ret.lastProposedPrice || 0,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editProduct(productId: string, batchId: string, editProduct: EditProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        productId: productId,
        batchId: batchId,
        editProduct: {
            images: editProduct.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            code: editProduct.code === null || editProduct.code === undefined ? null : editProduct.code,
            title: editProduct.title === null || editProduct.title === undefined ? null : editProduct.title,
            brand: editProduct.brand,
            size: editProduct.size === null || editProduct.size === undefined ? null : editProduct.size,
            suggestedValue: editProduct.suggestedValue || 0,
        },
    };
    const ret = await makeRequest({name: "editProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        title: ret.title === null || ret.title === undefined ? null : ret.title,
        brand: ret.brand,
        size: ret.size === null || ret.size === undefined ? null : ret.size,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(parseInt(ret.acceptedAt.split("-")[0], 10), parseInt(ret.acceptedAt.split("-")[1], 10) - 1, parseInt(ret.acceptedAt.split("-")[2], 10)),
        suggestedValue: ret.suggestedValue || 0,
        lastProposedPrice: ret.lastProposedPrice || 0,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function removeProduct(productId: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        productId: productId,
    };
    const ret = await makeRequest({name: "removeProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: ret.code === null || ret.code === undefined ? null : ret.code,
        title: ret.title === null || ret.title === undefined ? null : ret.title,
        brand: ret.brand,
        size: ret.size === null || ret.size === undefined ? null : ret.size,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(parseInt(ret.acceptedAt.split("-")[0], 10), parseInt(ret.acceptedAt.split("-")[1], 10) - 1, parseInt(ret.acceptedAt.split("-")[2], 10)),
        suggestedValue: ret.suggestedValue || 0,
        lastProposedPrice: ret.lastProposedPrice || 0,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function reviewProductsFromBatch(productReviews: ProductReview[], batchId: string, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        productReviews: productReviews.map(e => ({
            id: e.id,
            isAccepted: !!e.isAccepted,
            newSuggestedValue: e.newSuggestedValue === null || e.newSuggestedValue === undefined ? null : e.newSuggestedValue || 0,
        })),
        batchId: batchId,
    };
    const ret = await makeRequest({name: "reviewProductsFromBatch", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        code: e.code === null || e.code === undefined ? null : e.code,
        title: e.title === null || e.title === undefined ? null : e.title,
        brand: e.brand,
        size: e.size === null || e.size === undefined ? null : e.size,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(parseInt(e.acceptedAt.split("-")[0], 10), parseInt(e.acceptedAt.split("-")[1], 10) - 1, parseInt(e.acceptedAt.split("-")[2], 10)),
        suggestedValue: e.suggestedValue || 0,
        lastProposedPrice: e.lastProposedPrice || 0,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function exportCSVProductsSuppliers(filterByPeriod: FilterByPeriod | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterByPeriod: filterByPeriod === null || filterByPeriod === undefined ? null : {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "exportCSVProductsSuppliers", args, progress});
    return ret;
}

export async function getAllBatchesCreatedByAdmin(pageOffset: number, filter: FilterBatches | null, progress?: (progress: number) => void): Promise<Batch[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: filter === null || filter === undefined ? null : {
            supplierName: filter.supplierName === null || filter.supplierName === undefined ? null : filter.supplierName,
            supplierCode: filter.supplierCode === null || filter.supplierCode === undefined ? null : filter.supplierCode,
        },
    };
    const ret = await makeRequest({name: "getAllBatchesCreatedByAdmin", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userCreator: {
            id: e.userCreator.id,
            name: e.userCreator.name,
            email: e.userCreator.email,
            code: e.userCreator.code === null || e.userCreator.code === undefined ? null : e.userCreator.code,
            acceptedTermsOfUse: !!e.userCreator.acceptedTermsOfUse,
            createdAt: new Date(e.userCreator.createdAt + "Z"),
        },
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        code: e.code,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function getAllBatchesCreatedBySupplier(pageOffset: number, filter: FilterBatches | null, progress?: (progress: number) => void): Promise<Batch[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: filter === null || filter === undefined ? null : {
            supplierName: filter.supplierName === null || filter.supplierName === undefined ? null : filter.supplierName,
            supplierCode: filter.supplierCode === null || filter.supplierCode === undefined ? null : filter.supplierCode,
        },
    };
    const ret = await makeRequest({name: "getAllBatchesCreatedBySupplier", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userCreator: {
            id: e.userCreator.id,
            name: e.userCreator.name,
            email: e.userCreator.email,
            code: e.userCreator.code === null || e.userCreator.code === undefined ? null : e.userCreator.code,
            acceptedTermsOfUse: !!e.userCreator.acceptedTermsOfUse,
            createdAt: new Date(e.userCreator.createdAt + "Z"),
        },
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        code: e.code,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function getBatchById(batchId: string, progress?: (progress: number) => void): Promise<Batch> {
    const args = {
        batchId: batchId,
    };
    const ret = await makeRequest({name: "getBatchById", args, progress});
    return {
        id: ret.id,
        userCreator: {
            id: ret.userCreator.id,
            name: ret.userCreator.name,
            email: ret.userCreator.email,
            code: ret.userCreator.code === null || ret.userCreator.code === undefined ? null : ret.userCreator.code,
            acceptedTermsOfUse: !!ret.userCreator.acceptedTermsOfUse,
            createdAt: new Date(ret.userCreator.createdAt + "Z"),
        },
        userReceiver: ret.userReceiver === null || ret.userReceiver === undefined ? null : {
            id: ret.userReceiver.id,
            name: ret.userReceiver.name,
            email: ret.userReceiver.email,
            code: ret.userReceiver.code === null || ret.userReceiver.code === undefined ? null : ret.userReceiver.code,
            acceptedTermsOfUse: !!ret.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(ret.userReceiver.createdAt + "Z"),
        },
        code: ret.code,
        createdAt: new Date(ret.createdAt + "Z"),
        status: ret.status,
    };
}

export async function getAllBatchesReviewedBySupplierAdminMaster(supplierId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Batch[]> {
    const args = {
        supplierId: supplierId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllBatchesReviewedBySupplierAdminMaster", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userCreator: {
            id: e.userCreator.id,
            name: e.userCreator.name,
            email: e.userCreator.email,
            code: e.userCreator.code === null || e.userCreator.code === undefined ? null : e.userCreator.code,
            acceptedTermsOfUse: !!e.userCreator.acceptedTermsOfUse,
            createdAt: new Date(e.userCreator.createdAt + "Z"),
        },
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        code: e.code,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function getAllBatchesReviewedBySupplierForSupplier(pageOffset: number, progress?: (progress: number) => void): Promise<Batch[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllBatchesReviewedBySupplierForSupplier", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userCreator: {
            id: e.userCreator.id,
            name: e.userCreator.name,
            email: e.userCreator.email,
            code: e.userCreator.code === null || e.userCreator.code === undefined ? null : e.userCreator.code,
            acceptedTermsOfUse: !!e.userCreator.acceptedTermsOfUse,
            createdAt: new Date(e.userCreator.createdAt + "Z"),
        },
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        code: e.code,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function autocompleteBatchAdminMaster(code: string, supplierId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Batch[]> {
    const args = {
        code: code,
        supplierId: supplierId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "autocompleteBatchAdminMaster", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userCreator: {
            id: e.userCreator.id,
            name: e.userCreator.name,
            email: e.userCreator.email,
            code: e.userCreator.code === null || e.userCreator.code === undefined ? null : e.userCreator.code,
            acceptedTermsOfUse: !!e.userCreator.acceptedTermsOfUse,
            createdAt: new Date(e.userCreator.createdAt + "Z"),
        },
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        code: e.code,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function autocompleteBatchForSupplier(code: string, pageOffset: number, progress?: (progress: number) => void): Promise<Batch[]> {
    const args = {
        code: code,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "autocompleteBatchForSupplier", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userCreator: {
            id: e.userCreator.id,
            name: e.userCreator.name,
            email: e.userCreator.email,
            code: e.userCreator.code === null || e.userCreator.code === undefined ? null : e.userCreator.code,
            acceptedTermsOfUse: !!e.userCreator.acceptedTermsOfUse,
            createdAt: new Date(e.userCreator.createdAt + "Z"),
        },
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        code: e.code,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function confirmBatchCreation(batchId: string, progress?: (progress: number) => void): Promise<Batch> {
    const args = {
        batchId: batchId,
    };
    const ret = await makeRequest({name: "confirmBatchCreation", args, progress});
    return {
        id: ret.id,
        userCreator: {
            id: ret.userCreator.id,
            name: ret.userCreator.name,
            email: ret.userCreator.email,
            code: ret.userCreator.code === null || ret.userCreator.code === undefined ? null : ret.userCreator.code,
            acceptedTermsOfUse: !!ret.userCreator.acceptedTermsOfUse,
            createdAt: new Date(ret.userCreator.createdAt + "Z"),
        },
        userReceiver: ret.userReceiver === null || ret.userReceiver === undefined ? null : {
            id: ret.userReceiver.id,
            name: ret.userReceiver.name,
            email: ret.userReceiver.email,
            code: ret.userReceiver.code === null || ret.userReceiver.code === undefined ? null : ret.userReceiver.code,
            acceptedTermsOfUse: !!ret.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(ret.userReceiver.createdAt + "Z"),
        },
        code: ret.code,
        createdAt: new Date(ret.createdAt + "Z"),
        status: ret.status,
    };
}

export async function createBatch(userId: string | null, progress?: (progress: number) => void): Promise<Batch> {
    const args = {
        userId: userId === null || userId === undefined ? null : userId,
    };
    const ret = await makeRequest({name: "createBatch", args, progress});
    return {
        id: ret.id,
        userCreator: {
            id: ret.userCreator.id,
            name: ret.userCreator.name,
            email: ret.userCreator.email,
            code: ret.userCreator.code === null || ret.userCreator.code === undefined ? null : ret.userCreator.code,
            acceptedTermsOfUse: !!ret.userCreator.acceptedTermsOfUse,
            createdAt: new Date(ret.userCreator.createdAt + "Z"),
        },
        userReceiver: ret.userReceiver === null || ret.userReceiver === undefined ? null : {
            id: ret.userReceiver.id,
            name: ret.userReceiver.name,
            email: ret.userReceiver.email,
            code: ret.userReceiver.code === null || ret.userReceiver.code === undefined ? null : ret.userReceiver.code,
            acceptedTermsOfUse: !!ret.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(ret.userReceiver.createdAt + "Z"),
        },
        code: ret.code,
        createdAt: new Date(ret.createdAt + "Z"),
        status: ret.status,
    };
}

export async function getBrands(page: number, filter: FilterBrands | null, progress?: (progress: number) => void): Promise<Brand[]> {
    const args = {
        page: page || 0,
        filter: filter === null || filter === undefined ? null : {
            name: filter.name === null || filter.name === undefined ? null : filter.name,
        },
    };
    const ret = await makeRequest({name: "getBrands", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function createBrand(name: string, progress?: (progress: number) => void): Promise<Brand> {
    const args = {
        name: name,
    };
    const ret = await makeRequest({name: "createBrand", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createMultipleBrands(names: string[], progress?: (progress: number) => void): Promise<Brand[]> {
    const args = {
        names: names.map(e => e),
    };
    const ret = await makeRequest({name: "createMultipleBrands", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllNotificationsForAdminUser(progress?: (progress: number) => void): Promise<Notification[]> {
    const ret = await makeRequest({name: "getAllNotificationsForAdminUser", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        batch: {
            id: e.batch.id,
            userCreator: {
                id: e.batch.userCreator.id,
                name: e.batch.userCreator.name,
                email: e.batch.userCreator.email,
                code: e.batch.userCreator.code === null || e.batch.userCreator.code === undefined ? null : e.batch.userCreator.code,
                acceptedTermsOfUse: !!e.batch.userCreator.acceptedTermsOfUse,
                createdAt: new Date(e.batch.userCreator.createdAt + "Z"),
            },
            userReceiver: e.batch.userReceiver === null || e.batch.userReceiver === undefined ? null : {
                id: e.batch.userReceiver.id,
                name: e.batch.userReceiver.name,
                email: e.batch.userReceiver.email,
                code: e.batch.userReceiver.code === null || e.batch.userReceiver.code === undefined ? null : e.batch.userReceiver.code,
                acceptedTermsOfUse: !!e.batch.userReceiver.acceptedTermsOfUse,
                createdAt: new Date(e.batch.userReceiver.createdAt + "Z"),
            },
            code: e.batch.code,
            createdAt: new Date(e.batch.createdAt + "Z"),
            status: e.batch.status,
        },
        isRead: !!e.isRead,
        wasViewed: !!e.wasViewed,
        status: e.status,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllNotificationsForSupplier(progress?: (progress: number) => void): Promise<Notification[]> {
    const ret = await makeRequest({name: "getAllNotificationsForSupplier", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        userReceiver: e.userReceiver === null || e.userReceiver === undefined ? null : {
            id: e.userReceiver.id,
            name: e.userReceiver.name,
            email: e.userReceiver.email,
            code: e.userReceiver.code === null || e.userReceiver.code === undefined ? null : e.userReceiver.code,
            acceptedTermsOfUse: !!e.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(e.userReceiver.createdAt + "Z"),
        },
        batch: {
            id: e.batch.id,
            userCreator: {
                id: e.batch.userCreator.id,
                name: e.batch.userCreator.name,
                email: e.batch.userCreator.email,
                code: e.batch.userCreator.code === null || e.batch.userCreator.code === undefined ? null : e.batch.userCreator.code,
                acceptedTermsOfUse: !!e.batch.userCreator.acceptedTermsOfUse,
                createdAt: new Date(e.batch.userCreator.createdAt + "Z"),
            },
            userReceiver: e.batch.userReceiver === null || e.batch.userReceiver === undefined ? null : {
                id: e.batch.userReceiver.id,
                name: e.batch.userReceiver.name,
                email: e.batch.userReceiver.email,
                code: e.batch.userReceiver.code === null || e.batch.userReceiver.code === undefined ? null : e.batch.userReceiver.code,
                acceptedTermsOfUse: !!e.batch.userReceiver.acceptedTermsOfUse,
                createdAt: new Date(e.batch.userReceiver.createdAt + "Z"),
            },
            code: e.batch.code,
            createdAt: new Date(e.batch.createdAt + "Z"),
            status: e.batch.status,
        },
        isRead: !!e.isRead,
        wasViewed: !!e.wasViewed,
        status: e.status,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getCountOfNotViewedNotificationsForAdmin(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getCountOfNotViewedNotificationsForAdmin", args: {}, progress});
    return ret || 0;
}

export async function viewAllNotifications(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "viewAllNotifications", args: {}, progress});
    return undefined;
}

export async function readNotification(notificationId: string, progress?: (progress: number) => void): Promise<Notification> {
    const args = {
        notificationId: notificationId,
    };
    const ret = await makeRequest({name: "readNotification", args, progress});
    return {
        id: ret.id,
        userReceiver: ret.userReceiver === null || ret.userReceiver === undefined ? null : {
            id: ret.userReceiver.id,
            name: ret.userReceiver.name,
            email: ret.userReceiver.email,
            code: ret.userReceiver.code === null || ret.userReceiver.code === undefined ? null : ret.userReceiver.code,
            acceptedTermsOfUse: !!ret.userReceiver.acceptedTermsOfUse,
            createdAt: new Date(ret.userReceiver.createdAt + "Z"),
        },
        batch: {
            id: ret.batch.id,
            userCreator: {
                id: ret.batch.userCreator.id,
                name: ret.batch.userCreator.name,
                email: ret.batch.userCreator.email,
                code: ret.batch.userCreator.code === null || ret.batch.userCreator.code === undefined ? null : ret.batch.userCreator.code,
                acceptedTermsOfUse: !!ret.batch.userCreator.acceptedTermsOfUse,
                createdAt: new Date(ret.batch.userCreator.createdAt + "Z"),
            },
            userReceiver: ret.batch.userReceiver === null || ret.batch.userReceiver === undefined ? null : {
                id: ret.batch.userReceiver.id,
                name: ret.batch.userReceiver.name,
                email: ret.batch.userReceiver.email,
                code: ret.batch.userReceiver.code === null || ret.batch.userReceiver.code === undefined ? null : ret.batch.userReceiver.code,
                acceptedTermsOfUse: !!ret.batch.userReceiver.acceptedTermsOfUse,
                createdAt: new Date(ret.batch.userReceiver.createdAt + "Z"),
            },
            code: ret.batch.code,
            createdAt: new Date(ret.batch.createdAt + "Z"),
            status: ret.batch.status,
        },
        isRead: !!ret.isRead,
        wasViewed: !!ret.wasViewed,
        status: ret.status,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
