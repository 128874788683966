import {
	action,
	makeObservable,
	observable,
	runInAction,
} from "mobx";
import { saveAs } from "file-saver";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import PaginatedListStore from "~/stores/PaginatedListStore";
import { IRouterPusher } from "~/interfaces/IRouter";

export default class Store extends PaginatedListStore<api.Product> {
	private id: string;
	public loader = new LoaderShelf();
	public loaderPdf = new LoaderShelf();
	public supplier: api.AdminUser | null = null;
	public filterValue: Date | null = null;
	public dateFilterModal = new AttributeShelf(false);
	public batchesFilterModal = new AttributeShelf(false);
	public batchesFilterValue = new AttributeShelf<string | null>(null);
	public selectedBatch: api.Batch | null = null;

	public allProductListByBatchForPDF = new PaginatedListShelf<api.Product>(
		() => {
			return api.getGetSupplierApprovedProductsWithoutPagination(this.id, {
				acceptedAt: this.filterValue ? this.filterValue : null,
				batch: this.selectedBatch ? this.selectedBatch.id : null,
			});
		},
	);

	public getBatches = new PaginatedListShelf<api.Batch>(
		(page: number) => {
			if (!this.batchesFilterValue.value?.trim() || !this.batchesFilterValue.value){
				return api.getAllBatchesReviewedBySupplierAdminMaster(this.id, page);
			}
			return api.autocompleteBatchAdminMaster(this.batchesFilterValue.value, this.id, page);
		},
	);

	constructor(router: IRouterPusher, id: string) {
		super(router);
		this.id = id;
		this.getSupplier();
		this.fetchPage(0);
		makeObservable(this,{
			filterValue: observable,
			onFilterFetch: action,
			selectedBatch: observable,
		});
	}

	protected async getDataItemsPerPage(page: number): Promise<api.Product[]> {
		return api.getSupplierApprovedProducts(this.id,
			{
				acceptedAt: this.filterValue ? this.filterValue : null,
				batch: this.selectedBatch ? this.selectedBatch.id : null,
			}, page);
	}

	public setSelectedBatch = (batch: api.Batch) => {
		runInAction(() => this.selectedBatch = batch);
	};

	public getSupplier = async () => {
		this.loader.tryStart();
		try {
			const resultSupplier = await api.getAdminUser(this.id);
			runInAction(() => this.supplier = resultSupplier);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public setFilterValue = (date: Date) => {
		runInAction(() => this.filterValue = date);
	};

	public onFilterFetch = () => {
		this.fetchPage(0);
	};

	public generatePdf = async(pdfBlob: () => Promise<Blob>, code: string) => {
		this.loaderPdf.tryStart();
		try {
			const blob = await pdfBlob();
			saveAs(blob, `${code}.pdf`);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loaderPdf.end();
		}
	};
}
