import React from "react";
import {
	Document,
	Page,
	Text,
	View,
	Image,
	StyleSheet,
} from "@react-pdf/renderer";
import API from "~/resources/api";
import format from "~/resources/format";
import strings from "~/resources/strings";
import moment from "moment";
import api from "~/resources/api";

const styles = StyleSheet.create({
	page: {
		width: 992,
		alignItems: "center",
		backgroundColor: "#FFF",
		fontSize: 12,
	},
	titleContainer: {
		width: "96%",
		paddingHorizontal: 5,
		paddingVertical: 20,
		marginTop: 10,
		flexDirection: "row",
		borderWidth: 1,
		borderColor: "#EDF2F7",
	},
	titleContent: {
		width: "32%",
		alignItems: "center",
	},
	titleLabel: {
		fontWeight: "bold",
		color: "#80906F",
	},
	titleItemText: {
		color: "#4D5643",
	},
	table: {
		width: "95%",
	},
	row: {
		padding: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		borderBottomWidth: 1,
		borderBottomColor: "#EDF2F7",
	},
	rowItem: {
		width: "16.3%",
		paddingVertical: 3,
		marginHorizontal: "auto",
		textAlign: "center",
		color: "#4D5643",
	},
	headerItem: {
		marginTop: 20,
		width: "16.3%",
		marginBottom: 5,
		marginHorizontal: "auto",
		textAlign: "center",
		fontWeight: "bold",
		color: "#80906F",
	},
	image: {
		width: 60,
		height: 60,
	},
});

interface IProps {
	supplierName: string;
	code: string;
	email: string;
	products: API.Product[];
}

export const Pdf: React.FC<IProps> = (props) => {
	const {
		supplierName,
		code,
		email,
		products,
	} = props;

	const pageStrings = strings.suppliers.details;

	return(
		<Document>
			<Page size="A4" style={styles.page} wrap>
				<View style={styles.titleContainer}>
					<View style={styles.titleContent}>
						<View>
							<Text style={styles.titleLabel}>{pageStrings.supplier}</Text>
							<Text style={styles.titleItemText}>{supplierName}</Text>
						</View>
					</View>
					<View style={styles.titleContent}>
						<View>
							<Text style={styles.titleLabel}>{pageStrings.code}</Text>
							<Text style={styles.titleItemText}>{code}</Text>
						</View>
					</View>
					<View style={styles.titleContent}>
						<View>
							<Text style={styles.titleLabel}>{pageStrings.email}</Text>
							<Text style={styles.titleItemText}>{email}</Text>
						</View>
					</View>
				</View>
				<View style={styles.table}>
					<View style={styles.row} fixed>
						{pageStrings.header.map((value, index) => (
							<Text key={index} style={styles.headerItem}>{value}</Text>
						))}
					</View>
					{products.map((item, index)=>(
						<View key={index} style={styles.row} wrap={false}>
							<View style={styles.rowItem}>
								{item.images.length > 0 ? (
									<Image
										src={item.images[0].url}
										style={styles.image}
									/>
								): (
									<Text style={styles.rowItem}>{"-"}</Text>
								)}
							</View>
							<Text style={styles.rowItem}>{item.title}</Text>
							<Text style={styles.rowItem}>{item.brand}</Text>
							<Text style={styles.rowItem}>{item.size ? api.translateSize(item.size) : "-"}</Text>
							<Text style={styles.rowItem}>{format.currencyForBR(item.lastProposedPrice)}</Text>
							<Text style={styles.rowItem}>{item.acceptedAt ? moment(item.acceptedAt).format(strings.moment.date) : "-"}</Text>
						</View>
					))}
				</View>
			</Page>
		</Document>
	);
};
