import React from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerProps,
	DrawerContent,
	DrawerContentProps,
} from "@chakra-ui/react";

interface IProps extends DrawerProps {
	isOpen: boolean;
	onClose: () => void;
	drawerContentProps?: DrawerContentProps;
}

export const MobileDrawer: React.FC<IProps> = (props) => {
	const { children, isOpen, onClose, drawerContentProps } = props;
	return (
		<Drawer placement="left" isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent {...drawerContentProps}>
				<DrawerBody>
					{children}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
