import {
	action,
	makeObservable,
	observable,
	runInAction,
} from "mobx";
import { IRouterPusher } from "~/interfaces/IRouter";
import api from "~/resources/api";
import AuthStore from "~/stores/AuthStore";
import PaginatedListStore from "~/stores/PaginatedListStore";

export default class Store extends PaginatedListStore<api.Product> {
	private authStore: AuthStore;
	public filterValue: Date | null = null;

	constructor(router: IRouterPusher, authStore: AuthStore) {
		super(router);
		this.authStore = authStore;
		makeObservable(this,{
			filterValue: observable,
			onFilterDate: action,
		});
	}

	protected async getDataItemsPerPage(page: number): Promise<api.Product[]> {
		if(!this.authStore.currentAdminUser){
			return [];
		}
		if(this.filterValue){
			return api.getSupplierApprovedProducts(this.authStore.currentAdminUser.id,{ acceptedAt: this.filterValue, batch: null }, page);
		}
		return api.getSupplierApprovedProducts(this.authStore.currentAdminUser.id, null, page);
	}

	public setFilterValue = (date: Date) => {
		runInAction(() => this.filterValue = date);
	};

	public onFilterDate = () => {
		this.fetchPage(0);
	};
}
