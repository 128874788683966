import React from "react";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Center, Heading } from "@chakra-ui/react";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";

import { DrawerContent } from "../../../layout/Navbar/DrawerContent";
import {
	NotificationContent,
	Loading,
	Page,
	PageContent,
} from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";

const pageStrings = strings.home;

const Home: React.FC = () => {
	const { authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store(authStore));
	const history = useHistory();

	const goToBatchDetails = (id: string) => {
		history.push(`dashboard/batches/details/${id}`);
	};

	React.useEffect(() => {
		if (authStore.currentAdminUser && !store.loader.isLoading) {
			store.getNotifications();
		}
	}, [authStore.currentAdminUser]);

	return (
		<Page>
			<PageContent>
				{!authStore.currentAdminUser?.code && (
					<Heading
						variant="secondary"
						size="sm"
						color="primary.400"
						mb={spacing.s4}
						fontSize="lg"
					>
						{pageStrings.title}
					</Heading>
				)}

				{store.loader.isLoading || !authStore.currentAdminUser ? (
					<Center h={120}>
						<Loading />
					</Center>
				) : (
					authStore.currentAdminUser.code ? (
						<DrawerContent onClose={() => { }} showInPage />
					) : (
						<NotificationContent
							goToBatchDetails={goToBatchDetails}
							notifications={store.notifications}
							setNotificationRead={(id) => {
								store.setNotificationRead(id);
							}}
						/>
					)
				)}
			</PageContent>
		</Page>
	);
};

export default observer(Home);
