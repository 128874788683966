import { ComponentStyleConfig } from "@chakra-ui/react";

export const Heading: ComponentStyleConfig = {
	defaultProps: {
		variant: "primary",
		size: "md",
	},
	variants: {
		primary: {
			color: "primary.500",
		},
	},
};
