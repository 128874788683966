import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import {
	Button,
	VStack,
	useToast,
} from "@chakra-ui/react";
import {
	Card,
	TextInput,
	Logo,
	Page,
} from "~/components";
import { strings } from "~/resources";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useAuthRoute } from "~/hooks/useAuthRoute";
import spacing from "~/themes/spacing";

const Login: React.FC = () => {
	const pageStrings = strings.login;
	const toast = useToast();
	const { authStore } = useGlobalStore();
	const history = useHistory();

	const onSuccess = (userName: string) => {
		toast({status: "success", title: pageStrings.success(userName)});
		history.replace("/");
	};

	const onError = (errorMessage: string) => {
		toast({status: "error", title: errorMessage});
	};

	const login = () => {
		authStore.login(onSuccess, onError);
	};

	const toRecovery = () => {
		history.push("/recovery");
	};

	useAuthRoute(() => history.push("/dashboard"));

	return (
		<Page
			alignItems="center"
			bg="primary.700"
			minH="100vh"
		>
			<Card
				variant="form"
				maxW="25rem"
				w="100%"
				marginX={spacing.s2}
			>
				<VStack spacing="8" w="100%">
					<Logo />
					<TextInput
						labelText={pageStrings.fields.email}
						type="email"
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("email")}
					/>
					<TextInput
						labelText={pageStrings.fields.password}
						type="password"
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("password")}
					/>
					<Button
						w="12rem"
						onClick={login}
						isLoading={authStore.loader.isLoading}
					>
						{pageStrings.loginIn}
					</Button>
					<Button variant="link" onClick={toRecovery}>
						{pageStrings.recoveryPassword}
					</Button>
				</VStack>
			</Card>
		</Page>
	);
};

export default observer(Login);
