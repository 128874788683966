import { LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "../../../../resources/errors";
import { api } from "~/resources";
import PaginatedListStore from "~/stores/PaginatedListStore";
import { IRouterPusher } from "~/interfaces/IRouter";

interface IHandleResponse {
	onSucess: (userName: string) => void;
	onError?: (e: any) => void;
}

export default class Store extends PaginatedListStore<api.AdminUser> {

	public loader = new LoaderShelf();

	constructor(router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.AdminUser[]> {
		return api.getAllAdminUsers(page);
	}


	public deleteAdminUser = async (id: string, handleResponse: IHandleResponse) => {
		this.loader.tryStart();
		try{

			const deletedUser = await api.deleteAdminUser(id);

			handleResponse.onSucess(deletedUser.name);
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			if (handleResponse.onError) {
				handleResponse.onError(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
