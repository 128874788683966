import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import CreateOrEdit from "./CreateOrEditAdminUser";

const AdminUser: React.FC = () => {
	return (
		<Switch>
			<Route exact path="/dashboard/adminUsers" component={Table} />
			<Route
				path="/dashboard/adminUsers/create/"
				component={CreateOrEdit}
			/>
			<Route
				path="/dashboard/adminUsers/edit/:id"
				component={CreateOrEdit}
			/>

		</Switch>
	);
};

export default observer(AdminUser);
