import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Create from "./Create";
import Details from "./Details";
import Table from "./Table";

const Batches: React.FC = () => {
	return (
		<Switch>
			<Route
				exact
				path="/dashboard/batches"
				component={Table}
			/>
			<Route
				path="/dashboard/batches/create/:id?"
				component={Create}
			/>
			<Route
				path="/dashboard/batches/details/:id"
				component={Details}
			/>
		</Switch>
	);
};

export default observer(Batches);
