import React from "react";
import {
	Tr,
	Td,
	Text,
	Switch,
	FormControl,
} from "@chakra-ui/react";
import { MoneyInput, TableImageWithLabel } from "..";
import { format, strings, api } from "~/resources";
import { DetailsProduct } from "~/shelves/DetailsBatchShelf";

interface IProps{
	data: DetailsProduct;
	dataIndex: number;
	isDisabled: boolean;
	onChangeMoney: (index: number, value: string | null) => void;
	onChangeIsAccepted: (index: number, isAccepted: boolean) => void;
	setReviewedProduct: (id: string) => void;
	hasAnswered?: boolean;
}

const pageStrings = strings.components.tableReviewBatch;

export const TableReviewBatch: React.FC<IProps> = (props: IProps) => {
	const {
		data,
		dataIndex,
		isDisabled,
		setReviewedProduct,
		onChangeMoney,
		onChangeIsAccepted,
		hasAnswered,
	} = props;

	const accepted = !!data.acceptedAt || data.isAccepted;

	const [checked, setChecked] = React.useState(accepted);
	const [price, setPrice] = React.useState("");

	const imageUrls = data.images.map((image) => image.url);

	return (
		<Tr key={data.id} >
			<TableImageWithLabel imageUrls={imageUrls} code={data.code} />
			<Td>
				<Text>{data.brand}</Text>
			</Td>
			<Td>
				<Text>{data.title || "-"}</Text>
			</Td>
			<Td>
				<Text>{data.size ? api.translateSize(data.size) : "-"}</Text>
			</Td>
			<Td>
				<Text>{format.currencyForBR(data.suggestedValue)}</Text>
			</Td>
			{hasAnswered && (
				<Td>
					<Text>{data.acceptedAt ? pageStrings.accepted : pageStrings.refused}</Text>
				</Td>
			)}
			{hasAnswered ? (
				<>
					<Td>
						<Text color="gray.500">{format.currencyForBR(data.lastProposedPrice || 0)}</Text>
					</Td>
					<Td>
						<FormControl>
							<Switch
								defaultChecked={accepted}
								isDisabled={isDisabled || !!data.acceptedAt}
								onChange={() => {
									setReviewedProduct(data.id);
									setChecked(!checked);
									onChangeIsAccepted(dataIndex, !checked);
									if(!checked){
										onChangeMoney(dataIndex, null);
										setPrice("0");
									}
								}}
							/>
						</FormControl>
					</Td>
				</>
			):(
				<>
					<Td>
						<FormControl>
							<Switch
								defaultChecked={accepted}
								onChange={() => {
									setReviewedProduct(data.id);
									setChecked(!checked);
									onChangeIsAccepted(dataIndex, !checked);
									if(!checked){
										onChangeMoney(dataIndex, null);
										setPrice("0");
									}
								}}
							/>
						</FormControl>
					</Td>
					<Td>
						<MoneyInput
							value={price}
							defaultValue={data.newSuggestedValue ? format.decimal(data.newSuggestedValue) : null}
							isDisabled={isDisabled || checked}
							onChange={(value: string) => {
								onChangeMoney(dataIndex, value);
								setPrice(value);
							}}
						/>
					</Td>
				</>
			)}
		</Tr>
	);
};

