import React from "react";
import {
	Tr,
	Td,
	Text,
} from "@chakra-ui/react";
import {
	TableCellWithActionButtons,
	TableImageWithLabel,
} from "~/components";
import api from "~/resources/api";
import format from "~/resources/format";

interface IProps{
	data: api.Product;
	openDeleteDialog: (data: api.Product) => void;
	onEdit: () => void;
}

export const TableRowCreateProducts: React.FC<IProps> = (props: IProps) => {
	const { data, openDeleteDialog, onEdit } = props;

	const imageUrls = data.images.map((image) => image.url);
	return (
		<>
			<Tr key={data.id} >
				<TableCellWithActionButtons
					onEdit={onEdit}
					onDelete={() => {
						openDeleteDialog(data);
					}}
				/>
				<TableImageWithLabel imageUrls={imageUrls} code={data.code} />
				<Td>
					<Text>{data.title || "-"}</Text>
				</Td>
				<Td>
					<Text>{data.brand}</Text>
				</Td>
				<Td>
					<Text>{data.size ? api.translateSize(data.size) : "-"}</Text>
				</Td>
				<Td>
					<Text>{format.currencyForBR(data.suggestedValue)}</Text>
				</Td>
			</Tr>
		</>
	);
};
