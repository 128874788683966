import { makeAutoObservable } from "mobx";
import { FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";

interface IHandleResponse {
	onSuccess: () => void;
	onError: (e: any) => void;
}

export default class CreateOrEditAdminOrSupplierShelf {
	private isAdminUser: boolean;
	public form = new FormShelf({
		name: "",
		email: "",
		password: "",
		code: "",
	});

	public loader = new LoaderShelf();
	public id: string | null = null;

	constructor (isAdminUser: boolean, id?: string) {
		this.isAdminUser = isAdminUser;
		if (id) {
			this.id = id;
			this.getAdminUser(id);
		}
		makeAutoObservable(this);
	}

	public setValuesFormShelf = (adminUser: api.AdminUser) => {
		this.form = new FormShelf({
			email: adminUser.email,
			name: adminUser.name,
			password: "",
			code: adminUser.code || "",
		});
	};

	public getAdminUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const adminUser = await api.getAdminUser(id);
			this.setValuesFormShelf(adminUser);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	private createOrEdit = async (
		method: () => Promise<api.AdminUser>,
		handleResponse: IHandleResponse,
	) => {

		this.loader.tryStart();
		try {
			await method();

			handleResponse.onSuccess();
		} catch (error) {
			handleResponse.onError(Errors.handleError(error));
		} finally {
			this.loader.end();
		}
	};

	 public edit = (handleResponse: IHandleResponse, onError?: () => void) => {
		if (!this.id) {
			return;
		}
		const data = this.form.getValues();
		const id = this.id;
		if(!this.isAdminUser && data.code.length < 1 && onError){
			onError();
			return;
		}
		const editUser = () => api.editAdminUser(id, {
			...data,
			code: this.isAdminUser ? null : data.code,
		});
		this.createOrEdit(editUser, handleResponse);
	 };

	 public create = (handleResponse: IHandleResponse, onError?: () => void) => {
		const data = this.form.getValues();
		if(!this.isAdminUser && data.code.length < 1 && onError){
			onError();
			return;
		}
		this.createOrEdit(() => api.createAdminUser({
			...data,
			code: this.isAdminUser ? null : data.code,
		}), handleResponse);
	};

}
