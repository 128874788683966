import React from "react";
import { observer } from "mobx-react-lite";
import { Box, BoxProps, Select, SelectProps } from "@chakra-ui/react";
import { Label } from "..";
import spacing from "~/themes/spacing";

interface IProps<EnumType> extends SelectProps {
	label?: string;
	tranlateEnum: (item: EnumType) => string;
	onChangeEnum: (value: EnumType) => void;
	currentValue: EnumType | null;
	items: EnumType[];
	boxProps?: BoxProps;
	disabled?: boolean;
}

export const EnumPicker = observer(
	<EnumType extends string>(props: IProps<EnumType>) => {
		const {
			tranlateEnum,
			onChangeEnum,
			currentValue,
			boxProps,
			items,
			label,
			disabled,
			...rest
		} = props;

		return (
			<Box {...boxProps}>
				{label && <Label marginBottom={spacing.s1}>{label}</Label>}
				<Select
					isDisabled={disabled}
					{...rest}
					value={currentValue ? currentValue : ""}
					onChange={(e) => {
						const value = e.target.value as EnumType;
						onChangeEnum(value);
					}}
				>
					<option value="">{""}</option>
					{items.map((item, index) => (
						<option key={`${item}-${index}`} value={item}>
							{tranlateEnum(item)}
						</option>
					))}
				</Select>
			</Box>
		);
	},
);
