const IMG_EL = "img";
const CANVAS_EL = "canvas";
const CTX_2D_TYPE = "2d";

const pickerInputId = "imagePickerInput";
type InputUploadType = "image/*" | "*";

interface Sizing {
	startWidth: number;
	startHeight: number;
	maxSize: number;
}

const calculateSize = (data: Sizing) => {
	let width = data.startWidth;
	let height = data.startHeight;

	if (width > height) {
		if (width > data.maxSize) {
			height *= data.maxSize / width;
			width = data.maxSize;
		}
	} else {
		if (height > data.maxSize) {
			width *= data.maxSize / height;
			height = data.maxSize;
		}
	}
	return { width, height };
};

const extractImageData = (canvas: HTMLCanvasElement) => {
	return canvas.toDataURL("image/jpeg").split(",")[1];
};

export const craftImageBuffer = (
	file: File,
	maxSize: number,
	onResolve: (buffer: Buffer) => void,
) => {
	const reader = new FileReader();
	reader.onload = () => {
		const image = document.createElement(IMG_EL);
		image.onload = () => {
			const canvas = document.createElement(CANVAS_EL);
			const ctx = canvas.getContext(CTX_2D_TYPE);

			if (ctx) {
				ctx.drawImage(image, 0, 0);
				const { width, height } = calculateSize({
					startWidth: image.width,
					startHeight: image.height,
					maxSize,
				});
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(image, 0, 0, width, height);

				const buffer = Buffer.from(extractImageData(canvas), "base64");
				onResolve(buffer);
			} else {
				console.log("Context does not exist");
			}
		};

		if (reader.result) {
			image.src = reader.result as string;
		} else {
			console.log("Reader result is null");
			reader.abort();
		}
	};
	reader.readAsDataURL(file);
};

export const asyncCraftImageBuffer = async (file: File, size: number) => {
	return new Promise<Buffer>((resolve) =>
		craftImageBuffer(file, size, resolve),
	);
};

const removePreviousInputIfPresent = () => {
	const previousInput = document.getElementById(pickerInputId);
	if (previousInput) {
		document.body.removeChild(previousInput);
	}
};
export const openFileInput = (
	onResolve: (file: File, url: string) => void,
	onError: (message: string) => void,
	inputType: InputUploadType,
) => {
	removePreviousInputIfPresent();
	const input = document.createElement("input");
	input.setAttribute("id", pickerInputId);
	input.setAttribute("style", "display:none");
	input.setAttribute("type", "file");
	input.setAttribute("accept", inputType);
	document.body.appendChild(input);
	input.click();
	input.onchange = () => {
		if (input.files && input.files.length > 0) {
			const file = input.files[0];
			const url = URL.createObjectURL(file);

			onResolve(file, url);
		} else {
			onError("Arquivo selecionado é inválido");
		}
		document.body.removeChild(input);
	};
};
