import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	Flex,
	Text,
	Heading,
	useDisclosure,
} from "@chakra-ui/react";
import {
	Card,
	SearchSupplierModal,
	Table,
	ProductFormModal,
	Page,
	PageContent,
	TableRowCreateProducts,
	PageLoading,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import spacing from "~/themes/spacing";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";

interface IParams {
	id?: string;
}

const Create: React.FC = () => {
	const pageStrings = strings.batch.create;
	const commonBatchesStrings = strings.commonBatches.create;
	const { id } = useParams<IParams>();
	const history = useHistory();
	const { dialog, authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store(history, authStore, id));

	const {
		isOpen: isModalOpen,
		onOpen: openModal,
		onClose: closeModal,
	} = useDisclosure();

	const goBack = () => history.replace("/dashboard/batches");

	const openNeedSupplierDialog = () => {
		dialog.showDialog({
			title: pageStrings.needSupplierDialog.title,
			closeOnOverlayClick: true,
			description: pageStrings.needSupplierDialog.description,
			buttons: [
				{
					title: pageStrings.needSupplierDialog.ok,
					onPress: () => dialog.closeDialog(),
					outlined: false,
				},
			],
		});
	};

	const openDeleteDialog = (product: api.Product) => {
		dialog.showDialog({
			title: commonBatchesStrings.removeProductDialog.title,
			closeOnOverlayClick: true,
			description: commonBatchesStrings.removeProductDialog.description,
			buttons: [
				{
					title: strings.actions.delete,
					onPress: () => {
						store.createBatchShelf.productsShelf.removeProduct(product.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: strings.actions.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const openConfirmSupplierDialog = () => {
		dialog.showDialog({
			title: pageStrings.confirmSupplierDialog.title,
			closeOnOverlayClick: false,
			description: pageStrings.confirmSupplierDialog.description,
			buttons: [
				{
					title: strings.actions.confirm,
					onPress: () => {
						store.createBatchShelf.createBatch(
							store.suppliersShelf.selectedItem?.id,
							() => {
								store.suppliersShelf.refresh();
							},
						);
						dialog.closeDialog();
					},
					outlined: false,
				},
				{
					title: strings.actions.back,
					onPress: () => {
						store.suppliersShelf.search.clearValue();
						dialog.closeDialog();
					},
					outlined: true,
				},
			],
		});
	};

	const openConfirmSendDialog = () => {
		dialog.showDialog({
			title: commonBatchesStrings.confirmSendDialog.title,
			closeOnOverlayClick: false,
			description: commonBatchesStrings.confirmSendDialog.description,
			buttons: [
				{
					title: strings.actions.confirm,
					onPress: () => {
						store.createBatchShelf.confirmBatchCreation(goBack);
						dialog.closeDialog();
					},
					outlined: false,
				},
				{
					title: strings.actions.back,
					onPress: () => {
						dialog.closeDialog();
					},
					outlined: true,
				},
			],
		});
	};

	const isCreating = store.createBatchShelf.batch && (store.createBatchShelf.batch?.status === api.BatchStatus.creating);
	const isAdmin = !authStore.currentAdminUser?.code;

	React.useEffect(() => {
		if(store.isFetched && (!isCreating || !isAdmin)){
			history.replace("/");
		}
	},[store.isFetched]);

	if (!authStore.currentAdminUser) {
		return (<PageLoading />);
	}

	return (
		<Page>
			<PageContent>
				<Heading
					variant="secondary"
					size="sm"
					color="primary.400"
					mb={spacing.s3}
				>
					{pageStrings.title}
				</Heading>
				<Flex
					flexDir={["column", "column", "row"]}
					w="100%"
					mb={spacing.s4}
				>
					<Card>
						<Heading
							variant="secondary"
							size="md"
							color="primary.400"
							mr={spacing.s5}
							mb={{base: spacing.s3, md: 0}}
						>
							{strings.user.table.title}
						</Heading>
						<SearchSupplierModal
							searchValue={store.suppliersShelf.search.value}
							disabled={!!store.createBatchShelf.batch}
							closeOnOverlayClick={false}
							onClickSearch={store.suppliersShelf.onSearchByName}
							onChangeSearchValue={store.suppliersShelf.search.onChangeValue}
							onAdd={openConfirmSupplierDialog}
							onBack={store.suppliersShelf.search.clearValue}
							tableProps={{
								onSelectItem: store.suppliersShelf.onSelectItem,
								loading: store.suppliersShelf._loading,
								data: store.suppliersShelf._items,
								emptyMessage: strings.common.noResults,
								currentPage: store.suppliersShelf._page,
								isHaveNextPage: store.suppliersShelf._isHaveNextPage,
								prevPage: store.suppliersShelf.previousPage,
								nextPage: store.suppliersShelf.nextPage,
							}}
						/>
					</Card>
				</Flex>
				<Text mb={spacing.s3} >
					{strings.commonBatches.totalPages(
						store.createBatchShelf.productsShelf.totalPages,
					)}
				</Text>
				<Table
					minW="100%"
					maxHeightMobile={230}
					minHeightMobile={150}
					data={store.createBatchShelf.productsShelf._items}
					headers={commonBatchesStrings.header}
					renderRow={(item) => (
						<TableRowCreateProducts
							key={item.id}
							data={item}
							onEdit={() => {
								store.createBatchShelf.productsShelf.setProductData(item);
								openModal();
							}}
							openDeleteDialog={openDeleteDialog}
						/>
					)}
					emptyMessage={strings.common.noResults}
					{...store.createBatchShelf.productsShelf.getListProps()}
					onAdd={!!store.createBatchShelf.batch ? openModal : openNeedSupplierDialog}
				/>
				<ProductFormModal
					closeOnOverlayClick
					isOpen={isModalOpen}
					onClose={() => {
						store.createBatchShelf.productsShelf.clearFields();
						closeModal();
					}}
					disabledInputs={false}
					loading={false}
					onAddProduct={() => store.createBatchShelf.productsShelf.addOrEditProduct(closeModal)}
					shouldShowCodeField={!authStore.currentAdminUser.code}
					listProps={{
						onSelectItem: (item) => {
							store.brandsShelf.onSelectItem(item);
							store.createBatchShelf.productsShelf.setSelectedBrand(item.name);
						},
						emptyMessage: strings.common.noResults,
						...store.brandsShelf.getListProps(),
					}}
					{...store.createBatchShelf.productsShelf.getProductProps()}
					{...store.brandsShelf.getSearchProps()}
				/>
				<Button
					mt={spacing.s5}
					alignSelf="center"
					disabled={store.createBatchShelf.loader.isLoading || store.createBatchShelf.acceptTermLoader.isLoading}
					onClick={openConfirmSendDialog}
				>
					{strings.actions.send}
				</Button>
			</PageContent>
		</Page>
	);
};

export default observer(Create);
