import {
	AiOutlineHome,
	AiOutlineUsergroupAdd,
	AiOutlineReconciliation,
	AiOutlineSolution,
	AiOutlineQuestionCircle,
} from "react-icons/ai";
import { GrNotes } from "react-icons/gr";

import {
	CgProfile,
} from "react-icons/cg";

import { BiBox } from "react-icons/bi";

import strings from "../resources/strings";
import api from "../resources/api";

const useMainRoutes = (adminUser: api.AdminUser | null) => {
	const isSupplier = adminUser && adminUser.code;
	const commonRoutes = [
		{
			Icon: AiOutlineHome,
			text: strings.nav.home,
			path: "/dashboard",
		},
		!isSupplier &&
			{
				Icon: AiOutlineUsergroupAdd,
				text: strings.nav.adminUser,
				path: "/dashboard/adminUsers",
			},
		{
			Icon: AiOutlineReconciliation,
			text: strings.nav.batches,
			path: "/dashboard/batches",
		},
		{
			Icon: BiBox,
			text: strings.nav.supplierBatches,
			path: "/dashboard/supplierBatches",
		},
		!isSupplier &&
			{
				Icon: AiOutlineSolution,
				text: strings.nav.supplier,
				path: "/dashboard/suppliers",
			},

		isSupplier &&
		{
			Icon: CgProfile,
			text: "Perfil",
			path: "/dashboard/profile",
		},
		{
			Icon: AiOutlineQuestionCircle,
			text: strings.nav.faq,
			path: "/dashboard/faq",
		},
		{
			Icon: GrNotes,
			text: strings.nav.terms,
			path: "https://static.new4.com.br/adminFiles/N4A-RegrasGeraisdeConsignacaoFAQsimplificado.pdf",
		},
	];

	return commonRoutes;
};

export default useMainRoutes;
