import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Heading } from "@chakra-ui/react";
import spacing from "~/themes/spacing";

import API from "~/resources/api";
import { NotificationCard } from "./../NotificationCard";
import strings from "~/resources/strings";

interface IProps {
	notifications: API.Notification[];
	setNotificationRead: (id: string) => void;
	goToBatchDetails: (id: string) => void;
}

export const NotificationContent: React.FC<IProps> = observer((props) => {
	const { notifications, setNotificationRead, goToBatchDetails } = props;

	const onClickNotification = (notification: API.Notification) => {
		goToBatchDetails(notification.batch.id);
		setNotificationRead(notification.id);
	};

	const createNotificationText = (notification: API.Notification): string => {
		const {
			batchCreated,
			batchReviewed,
			batchAccepted,
			batchRefused,
			batchChanged,
		} = strings.home.notifications;
		const { userCreator, userReceiver } = notification.batch;

		switch(notification.status) {
			case API.NotificationType.created:
				return batchCreated(!!userCreator.code);
			case API.NotificationType.accepted:
				return batchAccepted(
					userReceiver ? userReceiver.name : null,
					userReceiver ? userReceiver.code : null,
				);
			case API.NotificationType.refused:
				return batchRefused(
					userReceiver ? userReceiver.name : null,
					userReceiver ? userReceiver.code : null,
				);
			case API.NotificationType.changed:
				return batchChanged(
					userReceiver ? userReceiver.name : null,
					userReceiver ? userReceiver.code : null,
				);
			case API.NotificationType.reviewed:
			default:
				return batchReviewed(
					userReceiver ? userReceiver.name : null,
					userReceiver ? userReceiver.code : null,
				);
		}
	};

	return (
		<>
			{notifications.length > 0 ? (
				notifications.map((notification) => (
					<NotificationCard
						key={notification.id}
						onClick={() => onClickNotification(notification)}
						text={createNotificationText(notification)}
						createdAt={notification.createdAt}
						notificationStatus={notification.status}
					/>
				))

			) : (
				<Flex
					my={spacing.s5}
					w="100%"
					justifyContent="center"
					minH={300}
					alignItems="center"
				>
					<Heading size="md" fontWeight="bold" color="primary.700">
						{strings.home.emptyNotifications}
					</Heading>
				</Flex>
			)
			}
		</>
	);
});
