import React from "react";
import { observer } from "mobx-react-lite";

import {
	ModalHeader,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	Flex,
	Box,
	Input,
	InputProps,
	Text,
} from "@chakra-ui/react";
import RDatePicker from "react-datepicker";
import spacing from "../../themes/spacing";
import strings from "~/resources/strings";
import { ptBR } from "date-fns/locale";

export interface IProps {
	title: string;
	isOpen: boolean;
	closeOnOverlayClick?: boolean;
	periodStart: Date | null;
	periodEnd: Date | null;
	onClose: () => void;
	onSelectDateStart: (date: Date) => void;
	onSelectDateEnd: (date: Date) => void;
	onClick: () => void;
	inputProps?: InputProps;
}

export const PeriodFilterModal: React.FC<IProps> = observer((props) => {

	const pageStrings = strings.user.table;

	const {
		title,
		isOpen,
		closeOnOverlayClick,
		periodStart,
		periodEnd,
		onClose,
		onSelectDateStart,
		onSelectDateEnd,
		onClick,
		inputProps,
	} = props;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent pt={spacing.s6} pb={spacing.s12}>
				<ModalHeader color="primary.500">{title}</ModalHeader>
				<ModalBody color="primary.500" pb="5rem">
					<Box>
						<Box py={spacing.s6}>
							<Text color="primary.500" size="md">{pageStrings.downloadCSV.filter.to}</Text>
							<RDatePicker
								locale={ptBR}
								dateFormat={"dd/MM/yyyy"}
								selected={periodStart}
								onChange={onSelectDateStart}
								customInput={<Input {...inputProps} />}
							/>
						</Box>
						<Box py={spacing.s6}>
							<Text color="primary.500" size="md">{pageStrings.downloadCSV.filter.from}</Text>
							<RDatePicker
								locale={ptBR}
								dateFormat={"dd/MM/yyyy"}
								selected={periodEnd}
								onChange={onSelectDateEnd}
								customInput={<Input {...inputProps} />}
							/>
						</Box>
					</Box>
				</ModalBody>

				<ModalFooter>
					<Flex flexDir="column">
						<Button
							mb={spacing.s8}
							w="12rem"
							onClick={() => {
								onClick();
								onClose();
							}}
						>
							{pageStrings.downloadCSV.filter.download}
						</Button>
						<Button
							variant="link"
							onClick={onClose}
						>
							{strings.actions.back}
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
});
