import React from "react";
import { Icon } from "@chakra-ui/react";
import { MobileDrawer } from "../../../components";
import { DrawerContent } from "../DrawerContent";
import { AiOutlineMenu } from "react-icons/ai";

export const NavbarLinks: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	return (
		<>
			<Icon
				as={AiOutlineMenu}
				onClick={() => setIsOpen(true)}
				cursor="pointer"
				color="terciary.100"
				w={35}
				h={35}
			/>
			<MobileDrawer
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			>
				<DrawerContent onClose={() => setIsOpen(false)}/>
			</MobileDrawer>
		</>
	);
};
