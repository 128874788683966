import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Box,
	Button,
	Heading,
	Text,
} from "@chakra-ui/react";
import {
	Loading,
	Page,
	PageContent,
	Table,
	TableReviewBatch,
	TotalProductsCard,
} from "~/components";
import spacing from "~/themes/spacing";
import { api, strings } from "~/resources";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory, useParams } from "react-router-dom";
import DetailsBatchShelf from "~/shelves/DetailsBatchShelf";

interface IParams {
	id: string;
}

const pageStrings = strings.commonBatches.details;

const Details: React.FC = () => {
	const { id } = useParams<IParams>();
	const history = useHistory();
	const { dialog, authStore } = useGlobalStore();
	const store = useLocalObservable(() => new DetailsBatchShelf(history, authStore, id));

	const goBack = () => history.goBack();

	const openDialog = () => {
		dialog.showDialog({
			title: pageStrings.modal.title,
			closeOnOverlayClick: true,
			description: pageStrings.modal.description,
			buttons: [
				{
					title: strings.actions.send,
					onPress: () => {
						store.reviewProductsFromBatch(()=> goBack());
						dialog.closeDialog();
					},
				},
				{
					title: strings.actions.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const isCreator = (authStore.currentAdminUser?.id === store.batch?.userCreator.id);
	const hasSameType = authStore.currentAdminUser?.code === store.batch?.userCreator.code;

	const isSupplier = !!authStore.currentAdminUser?.code;

	const isPending = store.batch?.status === api.BatchStatus.pending;
	const isPendingSupplier = store.batch?.status === api.BatchStatus.pendingSupplierReview;

	const invalidAccess = (!hasSameType && store.batch?.status === api.BatchStatus.creating);
	const isReviewer = (isSupplier && isPendingSupplier);

	const readOnly = (store.isReviewed || isCreator || hasSameType || invalidAccess);

	const isDisabled = (readOnly && !isReviewer) || (!isReviewer && !isPending);
	const showButton = (!store.isReviewed && (store.isPending && !isCreator) && !hasSameType) || isReviewer;

	return (
		<Page>
			{store.loader.isLoading && !authStore.currentAdminUser ? (
				<Loading mt={spacing.s10} />
			) : (
				<PageContent>
					{store.batch ? (
						<>
							<Box mb={spacing.s10}>
								<Heading
									variant="secondary"
									size="sm"
									color="primary.400"
									mb={spacing.s3}
								>
									{pageStrings.title(store.batch.code)}
								</Heading>
							</Box>
							<TotalProductsCard
								producstNumber={store.totalProducts}
								pagesNumber={store.totalPages}
								boxProps={{
									mb: spacing.s10,
								}}
							/>
							<Table
								minW="100%"
								data={store.products}
								headers={(isPendingSupplier || store.isReviewed) ? pageStrings.headerWithAnswer : pageStrings.header}
								renderRow={(item, index) => (
									<TableReviewBatch
										key={item.id}
										data={item}
										dataIndex={index}
										hasAnswered={(isPendingSupplier || store.isReviewed) || undefined}
										isDisabled={isDisabled}
										onChangeIsAccepted={store.onChangeIsAccepted}
										onChangeMoney={store.onChangeNewValue}
										setReviewedProduct={store.setReviewedProduct}
									/>
								)}
								loading={store._loading}
								emptyMessage={strings.common.noResults}
								currentPage={store._page}
								totalPages={store.totalPages}
								isHaveNextPage={store.hasNextPage}
								prevPage={store.previousPage}
								nextPage={store.nextPage}
							/>
							{
								(
									showButton
								)&&(
									<Button
										w={300}
										mt={spacing.s5}
										alignSelf="center"
										onClick={openDialog}
									>
										{strings.actions.send}
									</Button>
								)
							}
						</>
					) : (
						<Text>{pageStrings.noBatchToShow}</Text>
					)}
				</PageContent>
			)}
		</Page>
	);
};

export default observer(Details);
