import React from "react";
import {
	Flex,
	Text,
	TextProps,
} from "@chakra-ui/react";

import spacing from "~/themes/spacing";

interface IProps extends TextProps{ }

export const TextInfoComponent: React.FC<IProps> = (props) => {

	const {
	} = props;

	return (
		<Flex bg={"gray.300"} p={spacing.s3} borderRadius={5}>
			<Text
				display="flex"
				textAlign="center"
				variant="secondary"
				{...props}
			>
				{"Para melhorar a sua experiência posicione o celular na horizontal."}

			</Text>
		</Flex>
	);
};
