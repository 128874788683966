import { Button } from "./Button";
import { Input } from "./Input";
import { Modal } from "./Modal";
import { Heading } from "./Heading";
import { Switch } from "./Switch";
import { Text } from "./Text";
import { Card } from "./Card";
import { Table } from "./Table";
import { Select } from "./Select";
import { Accordion } from "./Accordion";

export const components = {
	Button,
	Input,
	Modal,
	Heading,
	Switch,
	Text,
	Card,
	Table,
	Select,
	Accordion,
};
