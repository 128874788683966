import React from "react";
import {
	Td,
	IconButton,
	Flex,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";

interface IProps {
	onDelete?: () => void;
	onEdit?: () => void;
}

export const TableCellWithActionButtons: React.FC<IProps> = (props) => {
	const {
		onDelete,
		onEdit,
	} = props;

	return (
		<Td>
			<Flex
				h="100%"
				w="100%"
				justifyContent="flex-start"
				alignItems="center"
				flexDirection="row"
			>
				{onEdit && (
					<IconButton
						variant="iconGreen"
						aria-label="Edit"
						size="xs"
						icon={<EditIcon />}
						onClick={onEdit}
					/>
				)}
				{onDelete && (
					<IconButton
						variant="iconGreen"
						size="xs"
						aria-label="Delete"
						onClick={onDelete}
						icon={<DeleteIcon />}
					/>
				)}
			</Flex>
		</Td>
	);
};
