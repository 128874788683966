import React from "react";
import {
	Td,
	Text,
	Flex,
} from "@chakra-ui/react";
import { ImageViewer } from "..";
import spacing from "~/themes/spacing";

interface IProps {
	imageUrls: string[];
	code: string | null;
}

export const TableImageWithLabel: React.FC<IProps> = (props) => {
	const {
		imageUrls,
		code,
	} = props;

	return (
		<Td>
			<Flex
				h="100%"
				w="100%"
				justifyContent="flex-start"
				alignItems="center"
				flexDirection="column"
			>
				<ImageViewer
					imageUrls={imageUrls}
				/>
				{code && (
					<Text mt={spacing.s2}>{code}</Text>
				)}
			</Flex>
		</Td>
	);
};
