import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction,
} from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import PaginatedListStore from "~/stores/PaginatedListStore";
import { IRouterPusher } from "~/interfaces/IRouter";
import format from "~/resources/format";
import strings from "~/resources/strings";
import AuthStore from "~/stores/AuthStore";
import ImagePickerShelf from "../ImagePicker";

const pageStrings = strings.commonBatches.details;

export interface DetailsProduct extends api.Product {
	newSuggestedValue: number | null;
	isAccepted: boolean;
}

export default class Store extends PaginatedListStore<DetailsProduct>{
	private batchId: string;
	private authStore: AuthStore;

	public loader = new LoaderShelf();
	public productsNumberLoader = new LoaderShelf();
	public acceptTermLoader = new LoaderShelf();
	public batch: api.Batch | undefined = undefined;
	public productsReviewed: api.ProductReview[] = [];
	public reviewedProducts: string[] = [];
	public isReviewed = false;
	public isPending = false;

	public firstImage = new ImagePickerShelf();
	public secondImage = new ImagePickerShelf();
	public thirdImage = new ImagePickerShelf();

	public totalPages = 1;
	public totalProducts = 0;

	constructor(router: IRouterPusher, authStore: AuthStore, batchId: string) {
		super(router);
		this.authStore = authStore;
		this.batchId = batchId;
		this.getBatch();
		this.fetch();
		this.getProductsNumber();
		makeObservable(this, {
			productsReviewed: observable,
			batch: observable,
			reviewedProducts: observable,
			isReviewed: observable,
			isPending: observable,
			totalPages: observable,
			totalProducts: observable,
			onChangeNewValue: action,
			onChangeIsAccepted: action,
			getBatch: action,
			setReviewedProduct: action,
			setInicialProductsReviewed: action,
			hasNextPage: computed,
			products: computed,
		});
	}
	public editProduct = async (dataId: string, batchId: string, editProduct: api.EditProduct) => {
		try {
			await api.editProduct(dataId, batchId, editProduct);
			showSuccessToast(pageStrings.success.changePrice);
			this.fetchPage(0);
		} catch (e) {
			showErrorToast(e);
		}
	};

	public get hasNextPage(): boolean {
		const currentPage = this._page + 1;
		const lastPage = this.totalPages;
		return lastPage > currentPage;
	}

	protected async getDataItemsPerPage(page: number): Promise<DetailsProduct[]> {
		const { items, totalPages } = await api.getProductsByBatch(this.batchId, page);
		runInAction(() => {
			this.totalPages = totalPages;
		});

		return items.map((item) => {
			this.setInicialProductsReviewed(item);
			return ({
				...item,
				newSuggestedValue: 0,
				isAccepted: !!item.acceptedAt,
			});
		});
	}

	public setInicialProductsReviewed = (data: api.Product) => {
		const productReviewIndex = this.productsReviewed.findIndex((product) => data.id === product.id);
		if (productReviewIndex === -1) {
			this.productsReviewed.push(
				{
					...data,
					newSuggestedValue: 0,
					isAccepted: !!data.acceptedAt,
				},
			);
		}
	};

	private fetch = async () => {
		await this.fetchPage(0);
	};
	private getProductsNumber = async () => {
		try {
			this.productsNumberLoader.tryStart(strings.error.stillLoading);
			const totalProducts = await api.getProductsNumberByBatch(this.batchId);
			runInAction(() => {
				this.totalProducts = totalProducts;
			});
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.productsNumberLoader.end();
		}
	};

	public getBatch = async () => {
		this.loader.tryStart();
		try {
			const resultBatch = await api.getBatchById(this.batchId);
			runInAction(() => {
				this.batch = resultBatch;
				this.isReviewed = this.batch.status === api.BatchStatus.reviewed;
				this.isPending = this.batch.status === api.BatchStatus.pending;
			});
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public onChangeNewValue = (productIndex: number, suggestedValue: string | null) => {
		const formatValue = (value: string | null) => {
			if (value) {
				return Number(format.cleanDecimal(value));
			}
			return null;
		};
		const productReviewIndex = this.productsReviewed.findIndex((product) => this._items[productIndex].id === product.id);
		this.productsReviewed[productReviewIndex].newSuggestedValue = formatValue(suggestedValue);
	};

	public onChangeIsAccepted = (productIndex: number, isAccepted: boolean) => {
		const productReviewIndex = this.productsReviewed.findIndex((product) => this._items[productIndex].id === product.id);
		this.productsReviewed[productReviewIndex].isAccepted = isAccepted;
	};

	public setReviewedProduct = (id: string) => {
		if (!this.reviewedProducts.includes(id)) {
			this.reviewedProducts.push(id);
		} else {
			runInAction(() => {
				this.reviewedProducts = this.reviewedProducts.filter((value) => value !== id);
			});
		}
	};

	public reviewProductsFromBatch = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const pendingReviews = [
				api.BatchStatus.pendingAdminReview,
				api.BatchStatus.pendingSupplierReview,
			];
			const isPendingReview = this.batch && pendingReviews.includes(this.batch.status);

			const hasNotReviewed = this.productsReviewed.length !== this.totalProducts;

			if (hasNotReviewed && !isPendingReview) {
				showErrorToast(pageStrings.error.needReview);
				return;
			}

			const data: api.ProductReview[] = this.productsReviewed.map((item) => {
				return {
					id: item.id,
					isAccepted: item.isAccepted,
					newSuggestedValue: item.newSuggestedValue || null,
				};
			});

			await api.reviewProductsFromBatch(data, this.batchId);
			showSuccessToast(pageStrings.success.sended);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public acceptTermOfUseAndReviewProducts = async (onSuccess: () => void) => {
		this.acceptTermLoader.tryStart();
		try {
			await api.acceptTermsOfUse();
			await this.reviewProductsFromBatch(onSuccess);
			await this.authStore.getCurrentAdminUser();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.acceptTermLoader.end();
		}
	};

	public get products() {
		return this._items.map((item) => {
			const productReviewIndex = this.productsReviewed.findIndex((product) => item.id === product.id);
			if (productReviewIndex > -1) {
				return {
					...item,
					newSuggestedValue: this.productsReviewed[productReviewIndex].newSuggestedValue,
					isAccepted: this.productsReviewed[productReviewIndex].isAccepted,
				};
			}
			return {
				...item,
				newSuggestedValue: null,
				isAccepted: false,
			};
		});
	}
}
