import React from "react";
import { observer } from "mobx-react-lite";
import {
	Center,
	Image,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	IconButton,
	Text,
	useDisclosure,
	useBreakpointValue,
} from "@chakra-ui/react";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
} from "@chakra-ui/icons";
import strings from "~/resources/strings";
import imagePlaceholder from "../../../static/pick_image.svg";

export interface IProps {
	imageUrls: string[];
}
export const ImageViewer: React.FC<IProps> = observer((props) => {
	const { imageUrls } = props;

	const componentStrings = strings.components.imageViewer;

	const modalSize = useBreakpointValue(["full", "xl"]);

	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const onClickPrevious = () => {
		const lastIndex = imageUrls.length - 1;
		const newIndex = selectedIndex === 0 ? lastIndex : selectedIndex - 1;
		setSelectedIndex(newIndex);
	};

	const onClickNext = () => {
		const lastIndex = imageUrls.length - 1;
		const newIndex = selectedIndex === lastIndex ? 0 : selectedIndex + 1;
		setSelectedIndex(newIndex);
	};

	const {
		isOpen: isModalOpen,
		onOpen: openModal,
		onClose: closeModal,
	} = useDisclosure();

	return (
		<>
			<Center
				width="80px"
				height="80px"
				position="relative"
				overflow="hidden"
			>
				<Tooltip
					label={
						imageUrls.length === 0
							? componentStrings.noImages
							: componentStrings.seeImages
					}
				>
					<Image
						h="100%"
						p={0}
						m={0}
						src={
							imageUrls.length === 0
								? imagePlaceholder
								: imageUrls[0]
						}
						onClick={imageUrls.length === 0 ? undefined : openModal}
						fallbackSrc={imagePlaceholder}
						cursor="pointer"
					/>
				</Tooltip>
			</Center>
			<Modal isOpen={isModalOpen}  onClose={closeModal} size={modalSize} scrollBehavior="inside" >
				<ModalOverlay />
				<ModalContent h="100%" w="100%" bg="gray.900">
					<ModalHeader bg="gray.900" >
						<ModalCloseButton onClick={closeModal} color="white" size="lg" />
					</ModalHeader>
					<ModalBody m={0} p={0} bg="gray.900">
						<Center
							position="relative"
							h="100%"
							w="100%"
							p={0}
							m={0}
							overflow="hidden"
						>
							<Image
								h="100%"
								w="100%"
								objectFit="contain"
								src={imageUrls[selectedIndex]}
								fallbackSrc={imagePlaceholder}
								p={0}
								m={0}
							/>
							<IconButton
								onClick={onClickPrevious}
								position="absolute"
								aria-label={componentStrings.previous}
								bg="transparent"
								h="100%"
								w="20%"
								maxW="80px"
								top={0}
								left={0}
								_focus={{boxShadow: "none"}}
								icon={<ArrowLeftIcon h="25px" w="25px" color="white" shadow="lg" />}
							/>
							<IconButton
								onClick={onClickNext}
								position="absolute"
								aria-label={componentStrings.next}
								bg="transparent"
								h="100%"
								w="20%"
								maxW="80px"
								top={0}
								right={0}
								_focus={{boxShadow: "none"}}
								icon={<ArrowRightIcon h="25px" w="25px" color="white" shadow="lg" />}
							/>
						</Center>
					</ModalBody>
					<ModalFooter py={0} m={0} bg="gray.900">
						<Text color="white" fontSize="md">
							{componentStrings.imageCount(selectedIndex + 1, imageUrls.length)}
						</Text>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});
