import React from "react";
import { observer } from "mobx-react-lite";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	Flex,
} from "@chakra-ui/react";
import spacing from "~/themes/spacing";
import strings from "~/resources/strings";

import { SearchInput } from "../SearchInput";
import { BatchList, BatchListProps } from "./BatchList";

interface IProps {
	title: string;
	onClick: () => void;
	isOpen: boolean;
	onClose: () => void;
	searchValue: string | null;
	onChangeSearchValue: (value: string) => void;
	onClickSearch: () => void;
	tableProps: BatchListProps;
}

export const BatchesFilterModal: React.FC<IProps> = observer((props) => {

	const {
		isOpen,
		onClose,
		title,
		onClick,
		searchValue,
		onClickSearch,
		onChangeSearchValue,
		tableProps,
	} = props;

	const componentStrings = strings.components.searchSupplierModal;

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader color="primary.500">{title}</ModalHeader>
				<ModalBody color="primary.500" px={spacing.s2}>
					<SearchInput
						isFilled
						name="supplierSearchModal"
						value={searchValue ? searchValue : ""}
						onChange={(e) => {
							onChangeSearchValue(e.currentTarget.value);
						}}
						onClickSearch={onClickSearch}
						placeholder={componentStrings.placeholder.searchName}
						containerProps={{ w: ["100%"], my: spacing.s8 }}
					/>
					{
						<BatchList
							searchValue={searchValue}
							onChangeSearchValue={onChangeSearchValue}
							{...tableProps}
						/>
					}
				</ModalBody>
				<ModalFooter>
					<Flex flexDir="column">
						<Button
							mb={spacing.s8}
							w="12rem"
							onClick={() => {
								onClick();
								onClose();
							}}
						>
							{strings.actions.select}
						</Button>
						<Button
							variant="link"
							onClick={onClose}
						>
							{strings.actions.back}
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
});
