import { makeAutoObservable } from "mobx";

export default class FilterShelf{
	public value = "";

	constructor() {
		makeAutoObservable(this);
	}


	public onSearch = (fetch: (page: number) => Promise<void>) => {
		fetch(0);
	};

	public onChangeValue = (value: string) => {
		this.value = value;
	};

	public clearValue = () => {
		this.value = "";
	};
}
