import React from "react";
import {
	Table as CTable,
	Thead,
	Tbody,
	Th,
	Tr,
	Flex,
	Heading,
	Button,
	BoxProps,
	Box,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Label, Card, Pagination, Loading } from "../";
import spacing from "../../themes/spacing";
import strings from "~/resources/strings";

interface IProps<DataType> extends BoxProps {
	data: DataType[];
	headers: string[];
	renderRow: (item: DataType, index: number) => React.ReactElement | null;
	loading: boolean;
	emptyMessage: string;
	currentPage: number;
	totalPages?: number;
	prevPage: () => void;
	nextPage: () => void;
	isHaveNextPage: boolean;
	onAdd?: () => void;
	maxHeightMobile?: number;
	minHeightMobile?: number;
}

export const Table = <DataType extends unknown>(
	props: IProps<DataType>,
) => {
	const {
		data,
		headers,
		renderRow,
		loading,
		emptyMessage,
		currentPage,
		totalPages,
		prevPage,
		nextPage,
		isHaveNextPage,
		onAdd,
		maxHeightMobile,
		minHeightMobile,
		...rest
	} = props;

	return (
		<Card
			d="initial"
			w={"80%"}
			px={0}
			{...rest}
		>
			<Box
				maxHeight={{base: maxHeightMobile || 350, md: 500}}
				overflowX="auto"
			>
				{loading || !data ? (
					<Flex my={spacing.s5} w={"100%"} justifyContent="center">
						<Loading />
					</Flex>
				) : data.length < 1 ? (
					<Flex
						my={spacing.s5}
						w="100%"
						justifyContent="center"
						minH={{base: minHeightMobile || 300, md: 300}}
						alignItems="center"
					>
						<Heading size="md" fontWeight="bold">
							{emptyMessage}
						</Heading>
					</Flex>
				) : (
					<CTable
						variant="simple"
						ml="auto"
						height="100%"
					>
						<Thead
							borderBottomColor="primary.500"
							borderBottomWidth={spacing.s2}
						>
							<Tr>
								{headers.map((header, index) => (
									<Th key={index}>
										<Label
											mx="auto"
											variant="secondary"
											fontWeight="bold"
											textAlign="center"
										>
											{header}
										</Label>
									</Th>
								))}
							</Tr>
						</Thead>
						<Tbody>
							{data.map((dataItem, index) => renderRow(dataItem, index))}
						</Tbody>
					</CTable>
				)}
			</Box>
			<Flex
				pt={spacing.s4}
				px={spacing.s6}
				alignItems="center"
				justifyContent={onAdd ? "space-between" : "flex-end"}
				borderTopColor="primary.500"
				borderTopWidth={spacing.s2}
			>
				{onAdd && (
					<Button rightIcon={<AddIcon />} onClick={onAdd}>
						{strings.actions.add}
					</Button>
				)}
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					prevPage={prevPage}
					nextPage={nextPage}
					isHaveNextPage={isHaveNextPage}
				/>
			</Flex>
		</Card>
	);
};
