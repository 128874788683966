import { makeAutoObservable, runInAction } from "mobx";
import {
	LoaderShelf,
} from "@startapp/mobx-utils";
import { IRouterPusher } from "~/interfaces/IRouter";
import CreateBatchShelf from "~/shelves/CreateBatchShelf";
import BrandsShelf from "~/shelves/BrandsShelf";
import { api, Errors, showErrorToast } from "~/resources";
import AuthStore from "~/stores/AuthStore";

export default class Store {

	public loader = new LoaderShelf();
	public isFetched = false;

	public createBatchShelf: CreateBatchShelf;
	public brandsShelf: BrandsShelf;

	constructor(router: IRouterPusher, authStore: AuthStore, id?: string) {
		this.createBatchShelf = new CreateBatchShelf(router, authStore);
		this.brandsShelf = new BrandsShelf(router);

		if(id){
			this.getBatch(id);
		}else{
			this.createBatchShelf.createBatch();
		}
		makeAutoObservable(this);
	}

	public getBatch = async (id: string) => {
		this.loader.tryStart();
		try {
			const resultBatch = await api.getBatchById(id);

			this.createBatchShelf.setBatch(resultBatch);
			this.createBatchShelf.productsShelf.setBatch(resultBatch);

			if(resultBatch.status !== api.BatchStatus.creating){
				return;
			}

			this.createBatchShelf.productsShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			runInAction(() => this.isFetched = true);
			this.loader.end();
		}
	};
}
