import { action, makeObservable, observable } from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import FilterShelf from "~/shelves/FIlterShelf";
import PaginatedListStore from "~/stores/PaginatedListStore";
import { IRouterPusher } from "~/interfaces/IRouter";

export default class SuppliersShelf extends PaginatedListStore<api.AdminUser> {
	public loader = new LoaderShelf();
	public selectedItem: api.AdminUser | null = null;
	public search = new FilterShelf();
	public suppliers: api.AdminUser[] = [];

	constructor(router: IRouterPusher) {
		super(router);
		makeObservable(this, {
			suppliers: observable,
			selectedItem: observable,
			onSearchByName: action,
			onSelectItem: action,
			getSuppliersByName: action,
		});
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.AdminUser[]> {

		if (this.search.value) {
			return this.getSuppliersByName(page);
		}

		return api.getAllSuppliers(page);
	}

	public onSearchByName = () => {
		this.search.onSearch(this.fetchPage);
	};

	public onSelectItem = (item: api.AdminUser) => {
		this.selectedItem = item;
	};

	public getSuppliersByName = async (page: number) => {
		this.loader.tryStart();
		try{
			const users = await api.getSuppliersByName(this.search.value, page);
			if (users.length < 1) {
				showErrorToast(strings.common.noResults);
			}
			this.suppliers = users;
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
		return this.suppliers;
	};

}
