import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	Heading,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import {
	Table,
	PageLoading,
	ProductFormModal,
	TableRowCreateProducts,
	Page,
	PageContent,
} from "~/components";
import { api, strings } from "~/resources";
import spacing from "~/themes/spacing";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";

interface IParams {
	id?: string;
}

const Create: React.FC = () => {
	const commonBatchesStrings = strings.commonBatches.create;
	const { id } = useParams<IParams>();
	const history = useHistory();
	const { dialog, authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store(history, authStore, id));

	const {
		isOpen: isModalOpen,
		onOpen: openModal,
		onClose: closeModal,
	} = useDisclosure();

	const {
		isOpen: isTermsOfUseOpen,
		onOpen: openTermsOfUse,
		onClose: closeTermsOfUse,
	} = useDisclosure();

	const goBack = () => history.replace("/dashboard/supplierBatches");

	const openDeleteDialog = (product: api.Product) => {
		dialog.showDialog({
			title: commonBatchesStrings.removeProductDialog.title,
			closeOnOverlayClick: true,
			description: commonBatchesStrings.removeProductDialog.description,
			buttons: [
				{
					title: strings.actions.delete,
					onPress: () => {
						store.createBatchShelf.productsShelf.removeProduct(product.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: strings.actions.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const openConfirmSendDialog = () => {
		dialog.showDialog({
			title: commonBatchesStrings.confirmSendDialog.title,
			closeOnOverlayClick: false,
			description: commonBatchesStrings.confirmSendDialog.description,
			buttons: [
				{
					title: strings.actions.confirm,
					onPress: () => {
						dialog.closeDialog();
						store.createBatchShelf.confirmBatchCreation(goBack);
					},
					outlined: false,
				},
				{
					title: strings.actions.back,
					onPress: () => {
						dialog.closeDialog();
					},
					outlined: true,
				},
			],
		});
	};

	const isCreating = store.createBatchShelf.batch && (store.createBatchShelf.batch?.status === api.BatchStatus.creating);
	React.useEffect(() => {
		if(!isCreating && store.isFetched){
			history.replace("/");
		}
	},[store.isFetched]);

	if (!authStore.currentAdminUser) {
		return (<PageLoading />);
	}

	return (
		<Page>
			<PageContent>
				<Heading
					variant="secondary"
					size="sm"
					color="primary.400"
					mb={spacing.s3}
				>
					{commonBatchesStrings.title}
				</Heading>
				<Text mb={spacing.s3} >
					{strings.commonBatches.totalPages(
						store.createBatchShelf.productsShelf.totalPages,
					)}
				</Text>
				<Table
					minW="100%"
					data={store.createBatchShelf.productsShelf._items}
					headers={commonBatchesStrings.header}
					renderRow={(item) => (
						<TableRowCreateProducts
							key={item.id}
							data={item}
							onEdit={() => {
								store.createBatchShelf.productsShelf.setProductData(item);
								openModal();
							}}
							openDeleteDialog={openDeleteDialog}
						/>
					)}
					emptyMessage={strings.common.noResults}
					{...store.createBatchShelf.productsShelf.getListProps()}
					onAdd={store.createBatchShelf.batch ? openModal : undefined}
				/>
				<ProductFormModal
					closeOnOverlayClick
					isOpen={isModalOpen}
					onClose={closeModal}
					disabledInputs={false}
					loading={false}
					onAddProduct={() => store.createBatchShelf.productsShelf.addOrEditProduct(closeModal)}
					shouldShowCodeField={!authStore.currentAdminUser.code}
					listProps={{
						onSelectItem: (item) => {
							store.brandsShelf.onSelectItem(item);
							store.createBatchShelf.productsShelf.setSelectedBrand(item.name);
						},
						emptyMessage: strings.common.noResults,
						...store.brandsShelf.getListProps(),
					}}
					{...store.createBatchShelf.productsShelf.getProductProps()}
					{...store.brandsShelf.getSearchProps()}
				/>
				<Button
					mt={spacing.s5}
					alignSelf="center"
					isLoading={store.loader.isLoading}
					onClick={openConfirmSendDialog}
				>
					{strings.actions.send}
				</Button>
			</PageContent>
		</Page>
	);
};

export default observer(Create);
