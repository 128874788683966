import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Box,
	Button,
	Flex,
	Heading,
	IconButton,
	Text,
} from "@chakra-ui/react";
import { pdf } from "@react-pdf/renderer";
import { DownloadIcon } from "@chakra-ui/icons";
import { AiOutlineFilter, AiOutlineReconciliation } from "react-icons/ai";
import {
	DatePickerModal,
	BatchesFilterModal,
	Loading,
	Page,
	PageContent,
	Table,
	TableRowProduct,
	UserInfoCard,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import { showErrorToast } from "~/resources/toast";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";
import { Pdf } from "./pdf";

interface IParams {
	id: string;
}

const DetailsView: React.FC = () => {

	const { authStore } = useGlobalStore();

	const history = useHistory();
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(history, id));

	const pageStrings = strings.suppliers.details;

	React.useEffect(() => {
		if (authStore.currentAdminUser) {
			store.fetchPage(0);
		}
	}, [authStore.currentAdminUser]);

	React.useEffect(()=>{
		if(store.batchesFilterModal.value){
			store.getBatches.fetchPage(0);
		}
	},[store.batchesFilterModal.value]);

	const generatePdfDocument = async (
		supplierName: string,
		code: string,
		email: string,
	) => {
		await store.allProductListByBatchForPDF.fetchPage(0);

		return store.generatePdf(
			pdf((
				<Pdf
					supplierName={supplierName}
					code={code}
					email={email}
					products={store.allProductListByBatchForPDF.items}
				/>
			)).toBlob,
			code,
		);
	};

	return (
		<Page>
			{store.loader.isLoading || store._loading ? (
				<Loading mt={spacing.s10} />
			) : (
				<PageContent
				>
					{store.supplier ? (
						<>
							<Box mb={spacing.s10}>
								<Heading
									variant="secondary"
									size="sm"
									color="primary.400"
									mb={spacing.s3}
								>
									{pageStrings.title}
								</Heading>
								<UserInfoCard
									supplier={store.supplier.name}
									code={store.supplier.code || ""}
									email={store.supplier.email}
								/>
							</Box>
							<Flex wrap="wrap">
								<Heading
									flexGrow={1}
									variant="secondary"
									size="sm"
									color="primary.400"
									mb={spacing.s2}
								>
									{pageStrings.parts}
								</Heading>
								<DatePickerModal
									title={pageStrings.filter.acceptanceDate}
									isOpen={store.dateFilterModal.value}
									onClose={() => store.dateFilterModal.setValue(false)}
									onClick={store.onFilterFetch}
									onChange={store.setFilterValue}
									selectedDate={store.filterValue || new Date()}
								/>
								<Box>
									<Button
										size="sm"
										mx={1}
										onClick={() => store.batchesFilterModal.setValue(true)}
										rightIcon={<AiOutlineReconciliation />}
										mb={spacing.s2}
									>
										{pageStrings.filter.batches}
									</Button>
									<Button
										size="sm"
										mx={1}
										onClick={() => store.dateFilterModal.setValue(true)}
										rightIcon={<AiOutlineFilter />}
										mb={spacing.s2}
									>
										{pageStrings.filter.acceptanceDate}
									</Button>
									<IconButton
										variant="iconGreen"
										aria-label="Download Pdf"
										isLoading={store.loaderPdf.isLoading}
										size="xs"
										mb={spacing.s2}
										icon={<DownloadIcon />}
										onClick={() => {
											if (store.supplier && store.supplier.code) {
												generatePdfDocument(
													store.supplier.name,
													store.supplier.code,
													store.supplier.email,
												);
											} else {
												showErrorToast(pageStrings.error.generatePdf);
											}
										}}
									/>
								</Box>
								<BatchesFilterModal
									title={pageStrings.filter.batches}
									isOpen={store.batchesFilterModal.value}
									onClose={() => store.batchesFilterModal.setValue(false)}
									onClick={store.onFilterFetch}
									onChangeSearchValue={store.batchesFilterValue.setValue}
									searchValue={store.batchesFilterValue.value}
									onClickSearch={() => store.getBatches.fetchPage(0)}
									tableProps={{
										onSelectItem: store.setSelectedBatch,
										loading: store.getBatches.loader.isLoading,
										data: store.getBatches.items,
										emptyMessage: strings.common.noResults,
										currentPage: store.getBatches.page,
										isHaveNextPage: store.getBatches.hasNextPage,
										prevPage: store.getBatches.previousPage,
										nextPage: store.getBatches.nextPage,
									}}
								/>
							</Flex>
							<Table
								minW="100%"
								data={store._items}
								headers={pageStrings.header}
								renderRow={(item) => (<TableRowProduct key={item.id} data={item} />)}
								loading={store._loading}
								emptyMessage={strings.common.noResults}
								currentPage={store._page}
								isHaveNextPage={store._isHaveNextPage}
								prevPage={store.previousPage}
								nextPage={store.nextPage}
							/>
						</>
					) : (
						<Text>{pageStrings.noSupplier}</Text>
					)}
				</PageContent>
			)}
		</Page>
	);
};

export default observer(DetailsView);
