import React from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface IProps{
	currentPage: number;
	totalPages?: number;
	prevPage: () => void;
	nextPage: () => void;
	isHaveNextPage: boolean;
}

export const Pagination: React.FC<IProps> = (props) => {
	const {
		currentPage,
		totalPages,
		prevPage,
		nextPage,
		isHaveNextPage,
	} = props;
	return(
		<Flex alignItems="center">
			<IconButton
				variant="iconGray"
				disabled={currentPage === 0}
				size="xs"
				aria-label="Left Icon"
				icon={<ChevronLeftIcon />}
				onClick={prevPage}
			/>
			<Text mx={3}>{currentPage+1}{totalPages && ` / ${totalPages}`}</Text>
			<IconButton
				variant="iconGray"
				disabled={!isHaveNextPage}
				size="xs"
				aria-label="Right Icon"
				icon={<ChevronRightIcon />}
				onClick={nextPage}
			/>
		</Flex>
	);
};
