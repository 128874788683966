import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Create from "./Create";
import Table from "./Table";
import Details from "./Details";

const SupplierBatches: React.FC = () => {
	return (
		<Switch>
			<Route
				exact
				path="/dashboard/supplierBatches"
				component={Table}
			/>
			<Route
				path="/dashboard/supplierBatches/create/:id?"
				component={Create}
			/>
			<Route
				path="/dashboard/supplierBatches/details/:id"
				component={Details}
			/>
		</Switch>
	);
};

export default observer(SupplierBatches);
