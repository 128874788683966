import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	VStack,
	Heading,
	useToast,
} from "@chakra-ui/react";
import {
	Card,
	Page,
	TextInput,
} from "~/components";
import { strings } from "~/resources";
import { useHistory, useParams } from "react-router-dom";
import CreateOrEditAdminOrSupplierShelf from "~/shelves/CreateOrEditAdminOrSupplierShelf";
import spacing from "~/themes/spacing";

interface IParams {
	id?: string;
}

const CreateOrEditSupplier: React.FC = () => {
	const pageStrings = strings.user.createOrEdit;
	const toast = useToast();
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalObservable(() => new CreateOrEditAdminOrSupplierShelf(false,id));

	const onSuccess = () => {
		toast({ status: "success", title: pageStrings.success(!!id) });
		history.goBack();
	};

	const onError = (errorMessage: string) => {
		toast({status: "error", title: errorMessage});
	};

	const onErrorCode = () => {
		toast({status: "error", title: pageStrings.errors.needCode});
	};

	const handleCreateOrEditSupplier = () => {
		if (id) {
			store.edit({onSuccess, onError}, onErrorCode);
			return;
		}
		store.create({onSuccess, onError}, onErrorCode);
	};

	const onBack = () => {
		history.goBack();
	};

	return (
		<Page
			alignItems="center"
			bg="primary.700"
		>
			<Card
				variant="form"
				maxW="25rem"
				w="100%"
				marginX={spacing.s2}
			>
				<VStack spacing={spacing.s8} w="100%">
					<Heading>
						{pageStrings.title}
					</Heading>
					<TextInput
						labelText={pageStrings.fields.name}
						type="text"
						isDisabled={store.loader.isLoading}
						{...store.form.field("name")}
					/>
					<TextInput
						labelText={pageStrings.fields.email}
						type="email"
						isDisabled={store.loader.isLoading}
						{...store.form.field("email")}
					/>
					{!id && (
						<TextInput
							labelText={pageStrings.fields.password}
							type="password"
							isDisabled={store.loader.isLoading}
							{...store.form.field("password")}
						/>
					)}
					<TextInput
						labelText={pageStrings.fields.code}
						type="text"
						isDisabled={store.loader.isLoading}
						{...store.form.field("code")}
					/>
					<Button
						w="11rem"
						onClick={handleCreateOrEditSupplier}
						isLoading={store.loader.isLoading}
					>
						{pageStrings.confirmButton(!!id)}
					</Button>
					<Button variant="link" onClick={onBack}>
						{pageStrings.backButton}
					</Button>
				</VStack>
			</Card>
		</Page>
	);
};

export default observer(CreateOrEditSupplier);
