import React from "react";
import { observer } from "mobx-react-lite";

import {
	ModalHeader,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	Flex,
} from "@chakra-ui/react";
import spacing from "../../themes/spacing";
import { DatePicker } from "../DatePicker";
import strings from "~/resources/strings";

export interface IProps  {
	title: string;
	isOpen: boolean;
	closeOnOverlayClick?: boolean;
	selectedDate: Date;
	onClose: () => void;
	onChange: (date: Date) => void;
	onClick: () => void;
}

export const DatePickerModal: React.FC<IProps> = observer((props) => {
	const {
		title,
		isOpen,
		closeOnOverlayClick,
		selectedDate,
		onClose,
		onChange,
		onClick,
	} = props;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent pt={spacing.s6} pb={spacing.s12}>
				<ModalHeader color="primary.500">{title}</ModalHeader>
				<ModalBody color="primary.500" pb="5rem">
					<DatePicker onChange={onChange} selectedDate={selectedDate} />
				</ModalBody>

				<ModalFooter>
					<Flex flexDir="column">
						<Button
							mb={spacing.s8}
							w="12rem"
							onClick={() => {
								onClick();
								onClose();
							}}
						>
							{strings.actions.select}
						</Button>
						<Button
							variant="link"
							onClick={onClose}
						>
							{strings.actions.back}
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

});

