import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import { MainLayout } from "../../layout";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import Batches from "./Batches";
import Suppliers from "./Suppliers";
import AdminUsers from "./AdminUsers";
import Profile from "./Profile";
import SupplierBatches from "./SupplierBatches";
import Faq from "./Faq";
import useCountNotifications from "~/hooks/useCountNotifications";

const Dashboard: React.FC = () => {
	useAuthRoute();
	const { notificationsCount, onReadAllNotifications } = useCountNotifications();

	return (
		<MainLayout countOfNewNotifications={notificationsCount} onClickRing={onReadAllNotifications}>
			<Switch>
				<Route exact path="/dashboard" component={Home} />
				<Route path="/dashboard/batches" component={Batches} />
				<Route path="/dashboard/suppliers" component={Suppliers} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/profile" component={Profile} />
				<Route path="/dashboard/supplierBatches" component={SupplierBatches} />
				<Route path="/dashboard/faq" component={Faq} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
