import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Flex } from "@chakra-ui/react";
import { ImagePicker } from "../ImagePicker";

import spacing from "../../themes/spacing";

export interface Image {
	src: string | null;
	pick: () => void;
	loading: boolean;
}

interface IProps {
	images: Image[];
}

export const MultipleImagePickers: React.FC<IProps> = observer((props) => {
	const { images } = props;
	return (
		<Flex
			flexDirection="row"
			flexWrap="wrap"
			justifyContent="space-between"
			alignItems="center"
			marginBottom={spacing.s3}
		>
			{images.map((image, index) => {
				return (
					<Box
						key={index}
						marginBottom={spacing.s3}
						marginRight={spacing.s1}
					>
						<ImagePicker
							src={image.src}
							pickImage={image.pick}
							loading={image.loading}
						/>
					</Box>
				);
			})}
		</Flex>
	);
});
