import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Box,
	Button,
	Heading,
	Text,
	useDisclosure,
} from "@chakra-ui/react";

import {
	Loading,
	Page,
	PageContent,
	Table,
	TableReviewBatchAdmin,
	TermsOfUseModal,
	TotalProductsCard,
} from "~/components";
import spacing from "~/themes/spacing";
import { api, showErrorToast, strings } from "~/resources";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory, useParams } from "react-router-dom";
import DetailsBatchShelf from "~/shelves/DetailsBatchShelf";

interface IParams {
	id: string;
}

const pageStrings = strings.commonBatches.details;
const commonBatchesStrings = strings.commonBatches.create;

const Details: React.FC = () => {
	const { id } = useParams<IParams>();
	const history = useHistory();

	const { dialog, authStore } = useGlobalStore();
	const store = useLocalObservable(() => new DetailsBatchShelf(history, authStore, id));
	const goBack = () => history.goBack();

	const {
		isOpen: isTermsOfUseOpen,
		onOpen: openTermsOfUse,
		onClose: closeTermsOfUse,
	} = useDisclosure();

	const openDialog = () => {
		dialog.showDialog({
			title: pageStrings.modal.title,
			closeOnOverlayClick: true,
			description: pageStrings.modal.description,
			buttons: [
				{
					title: strings.actions.send,
					onPress: () => {
						dialog.closeDialog();
						if (!authStore.currentAdminUser?.acceptedTermsOfUse && authStore.currentAdminUser?.code) {
							openTermsOfUse();
						} else {
							store.reviewProductsFromBatch(() => goBack());
						}
					},
				},
				{
					title: strings.actions.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const showHeaderStrings = () => {
		if ((isAdmin) && isPending) {
			return pageStrings.headerPending;
		}
		if ((isAdmin) && isPendingAdmin) {
			return pageStrings.headerAdminWithEditValue;
		}

		if ((!isAdmin) && isPendingAdmin) {
			return pageStrings.headerWithAnswer;
		}

		if (store.isReviewed) {
			return pageStrings.headerWithAnswer;
		}

		return pageStrings.header;
	};

	const isCreator = (authStore.currentAdminUser?.id === store.batch?.userCreator.id);
	const hasSameType = authStore.currentAdminUser?.code === store.batch?.userCreator.code;

	const isAdmin = !authStore.currentAdminUser?.code;

	const isPending = store.batch?.status === api.BatchStatus.pending;
	const isPendingAdmin = store.batch?.status === api.BatchStatus.pendingAdminReview;

	const invalidAccess = (!hasSameType && store.batch?.status === api.BatchStatus.creating);
	const isReviewer = (isAdmin && isPendingAdmin);

	const readOnly = (store.isReviewed || isCreator || hasSameType || invalidAccess);

	const isDisabled = (readOnly && !isReviewer) || (!isReviewer && !isPending);
	const showButton = (!store.isReviewed && (store.isPending && !isCreator) && !hasSameType) || isReviewer;

	return (
		<Page>
			{store.loader.isLoading && authStore.currentAdminUser ? (
				<Loading mt={spacing.s10} />
			) : (
				<PageContent>
					{store.batch ? (
						<>
							<Box mb={spacing.s10}>
								<Heading
									variant="secondary"
									size="sm"
									color="primary.400"
									mb={spacing.s3}
								>
									{pageStrings.title(store.batch.code)}
								</Heading>
							</Box>
							<TotalProductsCard
								producstNumber={store.totalProducts}
								pagesNumber={store.totalPages}
								boxProps={{
									mb: spacing.s10,
								}}
							/>
							<Table
								minW="100%"
								data={store.products}
								headers={showHeaderStrings()}
								renderRow={(item, index) => (
									<TableReviewBatchAdmin
										batchId={store.batch?.id}
										key={item.id}
										data={item}
										dataIndex={index}
										editProduct={store.editProduct}
										hasAnswered={(isPendingAdmin || store.isReviewed) || undefined}
										isDisabled={isDisabled}
										isPendingAdmin={isPendingAdmin}
										isPending={isPending}
										isReviewer={showButton || isReviewer}
										onChangeMoney={store.onChangeNewValue}
										onChangeIsAccepted={store.onChangeIsAccepted}
										firstImage={store.firstImage}
										secondImage={store.secondImage}
										thirdImage={store.thirdImage}
										setReviewedProduct={store.setReviewedProduct}
									/>
								)}
								loading={store._loading}
								emptyMessage={strings.common.noResults}
								currentPage={store._page}
								totalPages={store.totalPages}
								isHaveNextPage={store.hasNextPage}
								prevPage={store.previousPage}
								nextPage={store.nextPage}
							/>
							{
								(
									showButton || isReviewer
								) && (
									<Button
										w={300}
										mt={spacing.s5}
										alignSelf="center"
										onClick={openDialog}
									>
										{strings.actions.send}
									</Button>
								)
							}
						</>
					) : (
						<Text>{pageStrings.noBatchToShow}</Text>
					)}
				</PageContent>
			)}
			<TermsOfUseModal
				isOpen={isTermsOfUseOpen}
				onClose={() => {
					showErrorToast(commonBatchesStrings.error.needAcceptTermsOfUse);
					closeTermsOfUse();
				}}
				onAccept={() => {
					store.acceptTermOfUseAndReviewProducts(goBack);
					closeTermsOfUse();
				}}
			/>
		</Page>
	);
};

export default observer(Details);
