import LocalizedStrings from "localized-strings";

// MARK: Implementation
const strings = new LocalizedStrings({
	ptBR: {
		components: {
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			imageViewer: {
				seeImages: "Ver fotos",
				noImages: "Sem imagens",
				previous: "Anterior",
				next: "Próximo",
				imageCount: (current: number, total: number) => `${current} de ${total}`,
			},
			userInfoCard: {
				supplier: "Fornecedora",
				code: "Código",
				email: "E-mail",
			},
			searchSupplierModal: {
				placeholder: {
					searchName: "Procure por nome...",
					searchSupplier: "Escolha uma fornecedora...",
				},
				select: "Selecionar",
			},
			productFormModal: {
				title: "Adicionar Peças",
				fields: {
					code: "Código",
					title: "Titulo",
					brand: "Marca",
					size: "Tamanho",
					suggestedPrice: "Preço Sugerido",
					optional: "Opcional",
				},
				placeholder: {
					searchName: "Procure por nome...",
				},
			},
			totalProductsCard: {
				totalProductsNumber: "Total de peças: ",
				totalPages: "Total de páginas: ",
			},
			tableFilter: {
				searchByNamePlaceholder: "Procure por nome...",
				searchByCodePlaceholder: "Procure por código...",
			},
			tableReviewBatch: {
				accepted: "Aceito",
				refused: "Recusado",
				edit: "Alterar",
				descriptionModal: "Deseja alterar o preço deste produto?",
				successOnEdit: "Preço alterado com sucesso!",
			},
			termsOfUseModal: {
				title: "Termos de Uso",
				headerDescription: [
					"Será incluído sobre o valor da fornecedora",
					"60 % para itens até R$600",
					"50% para itens de R$601 até R$1.000",
					"40% para itens de R$1001 até R$ 2.000",
					"30% para itens de R$ 2001 até R$ 10.000",
					"20% acima de R$ 10.000",
					"25 dias úteis contados da conclusão da venda ao consumidor.",
				],
				description: [
					`A consignante Fornecedora acima qualificada, entrega em consignação à CALU COMÉRCIO DE ROUPAS ACESSÓRIOS E
					SERVIÇOS LTDA. (“N4A”) proprietária do marketplace “New4another”, com sede na Rua Campo Verde, 61, conjunto 71,
					Jardim Europa, São Paulo, SP, inscrita no CNPJ/ME sob o nº 42.506.175/0001-15, os Artigos acima descritos, para a
					finalidade de oferta, divulgação e venda dos mesmos no marketplace www.new4another.com.br, no showroom e perfis da
					N4A na mídias sociais.`,
					`A N4A declara receber, nesta data, o(s) Artigo(s) anexos, no estado em que se encontram, conforme vistoriados e
					fotografados. Os Artigos ficarão sob a guarda da N4A, em seu showroom, e serão colocados à venda por prazo
					indeterminado. A Fornecedora se compromete a deixar os produtos em consignação por um período mínimo de 3 meses
					para que a N4A possa fazer o trabalho de higienização, cadastro, fotos e anúncios em suas redes sociais e site.`,
					`Os Artigos serão divulgados pelo Valor da Fornecedora acordado entre as partes e anexado, podendo, mediante acordo (por",
					e-mail) entre as Partes, sofrer alterações para cima ou para baixo, caso as Partes identifiquem que o Valor da Fornecedora,
					está fora da realidade do mercado.`,
					`A N4A fará jus à comissão acima estabelecida. A comissão será retida pela N4A e o valor remanescente, deduzidas",
					eventuais taxas de transação, será repassado à Fornecedora, após a conclusão da venda e o efetivo recebimento dos,
					valores pagos pelo consumidor.`,
					`No caso de uma das Partes querer encerrar o contrato após os 3 meses mínimos estabelecidos, os Artigos que não tiverem",
					sido vendidos serão restituídos pela N4A à Fornecedora, na mesma condição que foram entregues. A devolução será,
					formalizada por meio de um termo de vistoria e entrega e os custos de devolução serão arcados da Parte que solicitou o,
					encerramento do Contrato.`,
					"Demais condições, consulte as “Regras Gerais para Venda em Consignação” da New4another.",
				],
			},
			nav: {
				countOfNotifications: (value: number) => value >= 100 ? "99+" : value,
			},
		},
		nav: {
			home: "Home",
			terms: "Termos de uso",
			batches: "Lotes",
			supplierBatches: "Lotes Fornecedora",
			supplier: "Fornecedoras",
			parts: "Peças",
			adminUser: "Usuárias Administradoras",
			logout: "Sair",
			close: "Fechar",
			faq: "FAQ",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			add: "Adicionar",
			send: "Enviar",
			back: "Voltar",
			select: "Selecionar",
			confirm: "Confirmar",
			close: "Fechar",
		},
		home: {
			title: "Notificações",
			notifications: {
				batchCreated: (isSenderSupplier: boolean) => `Você recebeu um novo Lote${isSenderSupplier ? " Pendente de Aprovação" : ""}`,
				batchReviewed: (senderName: string | null, senderCode: string | null) => {
					if (senderCode && senderName) {
						return `${senderName} (${senderCode}) atualizou o seu Lote enviado`;
					}
					return "A New4Another atualizou o seu lote enviado";
				},
				batchAccepted: (senderName: string | null, senderCode: string | null) => {
					if (senderCode && senderName) {
						return `${senderName} (${senderCode}) aceitou o seu Lote enviado`;
					}
					return "A New4Another aceitou o seu lote enviado";
				},
				batchRefused: (senderName: string | null, senderCode: string | null) => {
					if (senderCode && senderName) {
						return `${senderName} (${senderCode}) recusou o seu Lote enviado`;
					}
					return "A New4Another recusou o seu lote enviado";
				},
				batchChanged: (senderName: string | null, senderCode: string | null) => {
					if (senderCode && senderName) {
						return `${senderName} (${senderCode}) alterou o seu Lote enviado`;
					}
					return "A New4Another alterou o seu lote enviado";
				},
				at: (date: string) => `às  ${date}`,
			},
			emptyNotifications: "Não há notificações",
		},
		common: {
			noResults: "Sem Resultados",
			notLogged: "Usuária não está logada.",
		},
		errorPaginated: {
			type: "StillLoading",
			message: "Ainda estamos buscando os dados, aguarde.",
		},
		user: {
			table: {
				title: "Usuárias Fornecedoras",
				header: ["", "Usuária Fornecedora", "E-mail", "Código"],
				add: "Adicionar nova usuária",
				downloadCSV: {
					filter: {
						title: "Filtro de período",
						to: "De:",
						from: "Até:",
						download: "Download",
					},
				},
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				modal: {
					title: "Deletar",
					description: (userName: string) => `Tem certeza que quer deletar ${userName}?`,
					button: {
						delete: "Deletar",
						cancel: "Cancelar",
					},
				},
			},
			createOrEdit: {
				title: "Usuária Fornecedora",
				success: (hasId: boolean) => hasId ? "Fornecedora editada com sucesso" : "Fornecedora criada com sucesso",
				fields: {
					admin: "Usuária Fornecedora",
					name: "Nome",
					email: "E-mail",
					password: "Senha",
					code: "Código",
				},
				errors: {
					needCode: "Por favor, insira um código",
					noSearchSupplier: "Fornecedora não encontrada",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
				backButton: "Voltar",
			},
		},
		adminUser: {
			table: {
				title: "Usuárias Administradoras",
				header: ["", "Usuária Administradora", "E-mail"],
				add: "Adicionar nova usuária",
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				modal: {
					title: "Deletar",
					description: (userName: string) => `Tem certeza que quer deletar ${userName}?`,
					button: {
						delete: "Deletar",
						cancel: "Cancelar",
					},
				},
			},
			createOrEdit: {
				title: "Usuária Administradora",
				success: (hasId: boolean) => hasId ? "Administradora editada com sucesso" : "Administradora criada com sucesso",
				fields: {
					admin: "Usuária Administradora",
					name: "Nome",
					email: "E-mail",
					password: "Senha",
					code: "Código",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
				backButton: "Voltar",
			},
		},
		profile: {
			title: "Meu Perfil",
			parts: "Peças",
			header: ["Peças", "Título", "Marca", "Tamanho", "Valor Aceito", "Data de Aceite"],
			filter: "Filtro por data de aceite",
		},
		productShelf: {
			added: "Produto adicionado com sucesso",
			success: {
				productRemoved: "Produto removido com sucesso!",
				productEdited: "Produto editado com sucesso!",
			},
			error: {
				needSelected: "É necessário selecionar uma fornecedora.",
				batchAlreadySent: "Lote já enviado",
			},
		},
		batch: {
			table: {
				title: "Lotes",
				searchByNamePlaceholder: "Procure por nome...",
				searchByCodePlaceholder: "Procure por código...",
			},
			create: {
				title: "Lotes",
				needSupplierDialog: {
					title: "Selecione uma fornecedora",
					description: "Selecione uma fornecedora para que o seu lote seja criado e você possa começar a adicionar peças.",
					ok: "OK",
				},
				confirmSupplierDialog: {
					title: "Confirmação",
					description: "Não será possível modificar a fornecedora após selecionar. Deseja continuar?",
				},
			},

		},
		commonBatches: {
			totalPages: (totalPages: number) => `Total de páginas: ${totalPages}`,
			table: {
				header: ["Status", "Usuária Fornecedora", "Código do Lote", "Data de envio"],
				success: {
					batchCreated: (code: string) => `Lote ${code} criado com sucesso!`,
				},
				error: {
					needSelected: "É necessário selecionar uma fornecedora.",
				},
			},
			create: {
				title: "Lote",
				header: ["", "Peças", "Título", "Marca", "Tamanho", "Valor Sugerido"],
				success: {
					productRemoved: "Produto removido com sucesso!",
					batchConfirmed: "Lote enviado com sucesso!",
				},
				error: {
					needSelected: "É necessário selecionar uma fornecedora.",
					batchAlreadySent: "Lote já enviado",
					needAcceptTermsOfUse: "É necessário aceitar os termos de uso para executar esta ação",
				},
				confirmSendDialog: {
					title: "Confirmação",
					description: "Não será possível modificar o lote após enviar. Deseja continuar?",
				},
				removeProductDialog: {
					title: "Deletar produto",
					description: "Tem certeza que deseja deletar o produto?",
				},
				products: {
					productAdded: "Produto adicionado com sucesso",
				},
			},
			details: {
				title: (batchCode: string) => `Lote: ${batchCode}`,
				noBatchToShow: "Nenhum lote para ser exibido",
				header: ["Foto", "Marca", "Título", "Tamanho", "Preço", "De Acordo", "Novo Valor Sugerido"],
				headerPending: ["Foto", "Marca", "Título", "Tamanho", "Preço", "De Acordo", "Alterar Preço"],
				headerAdminWithEditValue: ["Foto", "Marca", "Título", "Tamanho", "Preço", "Resposta", "Novo Valor Sugerido", "De Acordo", "Editar Valor"],
				headerWithAnswer: ["Foto", "Marca", "Título", "Tamanho", "Preço", "Resposta", "Novo Valor Sugerido", "De Acordo"],
				modal: {
					title: "Enviar Lote",
					description: "Não será possível editar após esta ação, deseja enviar mesmo assim?",
				},
				success: {
					sended: "Revisão do Lote enviada com sucesso!",
					changePrice: "Preço alterado com sucesso!",
				},
				error: {
					needReview: "Há produtos que não foram revisados.",
				},
			},
		},
		supplierBatches: {
			table: {
				title: "Lotes da Fornecedora",
			},
		},
		suppliers: {
			details: {
				title: "Fornecedora",
				parts: "Peças",
				supplier: "Fornecedora:",
				code: "Código:",
				email: "E-mail:",
				header: ["Foto", "Título", "Marca", "Tamanho", "Valor Aceito", "Data de Aceite"],
				filter: {
					acceptanceDate: "Filtro por data de aceite",
					batches: "Lotes",
				},
				noSupplier: "Fornecedor não encontrado.",
				error: {
					generatePdf: "Ocorreu um erro ao tentar gerar pdf",
				},
			},
		},
		login: {
			loginIn: "Login",
			recoveryPassword: "Recuperar Senha",
			success: (userName: string) => `Bem vindo ${userName}.`,
			fields: {
				email: "E-mail",
				password: "Senha",
			},
		},
		imagePicker: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		register: {
			title: "Usuária fornecedora",
			success: (hasId: boolean) => hasId ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
			fields: {
				admin: "Usuária Administradora",
				name: "Nome",
				email: "E-mail",
				password: "Senha",
			},
			confirmButton: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
			backButton: "Voltar",
		},
		recoveryPage: {
			success: "Bem vindo!",
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			token: "Token",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
		faq: {
			title: "Perguntas Frequentes",
			questions: [
				{
					question: "O que é lote?",
					answer: `
						Lote é um conjunto de peças de uma fornecedora cujos preços a New4another quer validar
						para colocar à venda.
					`,
				},
				{
					question: "Quem é a fornecedora?",
					answer: `
						Fornecedoras são as pessoas que colocam seus produtos à venda de forma consignada na
						New4another.
					`,
				},
				{
					question: "Qual a diferença do Lote para o Lote Fornecedora?",
					answer: `
						Lote está relacionado com o lote criado pela New4another para uma determinada
						fornecedora.
						Lote fornecedora está relacionado ao lote criado pela fornecedora.
					`,
				},
				{
					question: "Como eu faço para ver as minhas peças que estão à venda na New4another?",
					answer: `
						Faça login no dashboard, e então clique em Perfil. Lá, você verá todas as suas peças que a
						new4another já está autorizada a vender!
					`,
				},
				{
					question: "Como eu faço para ver todas as peças enviadas para a New4another?",
					answer: `
						Você vai precisar abrir os lotes que a New4another já te enviou e ver todas as peças que
						estão com elas. Peças cujo preço você recusou não estarão à venda.
					`,
				},
				{
					question: "Se eu estiver na página de perfil, como eu vou para outra página?",
					answer: `
						Basta clicar no menu sanduíche (os 3 tracinhos) no canto esquerdo superior da tela. Não só
						quando estiver em Perfil, como em qualquer outra página.
					`,
				},
				{
					question: "Consigo incluir mais peças no Lote que a New4another me enviou?",
					answer: `
						Não. Você só consegue aprovar ou recusar valores das peças que a New4another te mandou,
						e propor novos valores. É impossível adicionar mais peças.
					`,
				},
				{
					question: "Como criar um Lote Fornecedora?",
					answer: `
						Você vai em Lotes Fornecedora, clica no botão “Adicionar +” para criar um lote seu.
					`,
				},
				{
					question: "Como eu incluo peças no meu Lote Fornecedora?",
					answer: `
						Você clica no botão “Adicionar +” dentro do seu Lote Fornecedora para colocar peças suas
						nesse lote.
					`,
				},
				{
					question: "Como eu faço para excluir uma peça?",
					answer: `
						Fique atenta! Você só consegue excluir peças de Lotes Fornecedoras que você não enviou
						ainda.
					`,
				},
				{
					question: "Eu consigo editar informações do meu Lote Fornecedora?",
					answer: `
						Você consegue editar informações de seu Lote Fornecedora apenas antes de ENVIAR o Lote.
						Uma vez que você o enviou, você não consegue editar mais as informações!
					`,
				},
			],
		},
		enum: {
			BatchStatus: {
				creating: "Criando",
				pending: "Pendente",
				reviewed: "Revisado",
				pendingSupplierReview: "Revisão pendente",
				pendingAdminReview: "Revisão pendente",
			},
			Size: {
				sXS: "PP",
				sS: "P",
				sM: "M",
				sL: "G",
				s34: "34",
				s35: "35",
				s36: "36",
				s37: "37",
				s38: "38",
				s39: "39",
				s40: "40",
				s41: "41",
				s42: "42",
				s43: "43",
				s44: "44",
				s45: "45",
				s46: "46",
			},
		},
	},
});

export default strings;
